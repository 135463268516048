import request from "./request";

export function login(data) {
  return request({
    url: "/v7LqUik2b6kW",
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  });
}
export function sendLoginSms(data) {
  return request({
    url: "/1SURAyJtO2uY",
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  });
}
