// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/bottom-bg-mine.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/images/bottom-bg-index.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  position: fixed;
  z-index: 1000;
  left: 0;
  bottom: 0;
  height: 0.94rem;
  padding-left: 0.57rem;
  padding-right: 0.57rem;
  display: flex;
  align-items: center;
  width: 2.61rem;
  background: #ffffff;
}
.footer.my {
  background: #FFFFFF url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat left top;
  background-size: 3.75rem 0.94rem;
}
.footer.index {
  background: #FFFFFF url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat left top;
  background-size: 3.75rem 0.94rem;
}
.footer .item {
  width: 0.88rem;
  height: 0.66rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer .item img {
  width: 0.88rem;
  height: 0.26rem;
}
.footer .item.mine {
  margin-left: 0.85rem;
}
`, "",{"version":3,"sources":["webpack://./src/assets/less/footer.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,OAAA;EACA,SAAA;EACA,eAAA;EACA,qBAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;EACA,mBAAA;AACF;AAAE;EACE,8EAAA;EACA,gCAAA;AAEJ;AAAE;EACE,8EAAA;EACA,gCAAA;AAEJ;AApBA;EAqBI,cAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAEJ;AA3BA;EA2BM,cAAA;EACA,eAAA;AAGN;AADI;EACE,oBAAA;AAGN","sourcesContent":[".footer {\n  position: fixed;\n  z-index: 1000;\n  left: 0;\n  bottom: 0;\n  height: .94rem;\n  padding-left: .57rem;\n  padding-right: .57rem;\n  display: flex;\n  align-items: center;\n  width: 2.61rem;\n  background: #ffffff;\n  &.my {\n    background: #FFFFFF url(\"../../assets/images/bottom-bg-mine.webp\") no-repeat left top;\n    background-size: 3.75rem 0.94rem;\n  }\n  &.index {\n    background: #FFFFFF url(\"../../assets/images/bottom-bg-index.webp\") no-repeat left top;\n    background-size: 3.75rem 0.94rem;\n  }\n  .item {\n    width: .88rem;\n    height: .66rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    img {\n      width: .88rem;\n      height: .26rem;\n    }\n    &.mine {\n      margin-left: .85rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
