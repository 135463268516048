import * as React from "react"
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  Outlet,
  HashRouter,
  useNavigate,
} from "react-router-dom"
import { isModel6666 } from "../src/api/v2/loan"
import { fakeAuthProvider } from "./auth"
import "./App.less"
import Privacy from "./views/login/privacy"
import PrivacyV2 from "./views/v2/login/privacy"
import Home from "./views/home"
import HomeV2 from "./views/v2/home"
import { useEffect, useState, lazy } from "react"
import { getCookie } from "./utils/tools"
import MineIndex from "./views/mine"
import MineIndexV2 from "./views/v2/mine"
import { ConfigProvider } from "antd-mobile"
import enUS from "antd-mobile/es/locales/en-US"
import { reactLocalStorage } from "reactjs-localstorage"
import "antd-mobile/es/global"
import { Modal } from "antd-mobile"

const Settings = lazy(() => import("./views/mine/settings"))
const SettingsV2 = lazy(() => import("./views/v2/mine/settings"))
const Protocols = lazy(() => import("./views/mine/protocols"))
const ProtocolsV2 = lazy(() => import("./views/v2/mine/protocols"))
const Feedback = lazy(() => import("./views/mine/feedback"))
const Orders = lazy(() => import("./views/mine/orders"))
const ModifyBank = lazy(() => import("./views/home/modify-bank"))
const ModifyBankV2 = lazy(() => import("./views/v2/home/modify-bank"))
const LoanConfirm = lazy(() => import("./views/home/confirm"))
const LoanConfirmV2 = lazy(() => import("./views/v2/home/confirm"))
const Face = lazy(() => import("./views/home/face"))
const Bank = lazy(() => import("./views/home/bank"))
const Contact = lazy(() => import("./views/home/contact"))
const Info = lazy(() => import("./views/home/info"))
const Kyc = lazy(() => import("./views/home/kyc"))
const Reloan = lazy(() => import("./views/home/reloan"))
const Login = lazy(() => import("./views/login/login"))
const LoginV2 = lazy(() => import("./views/v2/login/login"))
const LoginSms = lazy(() => import("./views/login/login-sms"))
const LoginSmsV2 = lazy(() => import("./views/v2/login/login-sms"))
const StepsV2 = lazy(() => import("./views/v2/home/steps"))
const InfoV2 = lazy(() => import("./views/v2/home/info"))
const KycV2 = lazy(() => import("./views/v2/home/kyc"))
const ContactV2 = lazy(() => import("./views/v2/home/contact"))
const BankV2 = lazy(() => import("./views/v2/home/bank"))
const ReviewV2 = lazy(() => import("./views/v2/home/review"))
const RefusedV2 = lazy(() => import("./views/v2/home/reject"))
const RepayV2 = lazy(() => import("./views/v2/home/repay"))

window.switchBack = (type, t) => {
  try {
    console.log("switchBack", type)
    const page = reactLocalStorage.get("currentPage")
    let backTime = reactLocalStorage.getObject("backTime")
    let pageBackTime = backTime[page] || []
    switch (type) {
      case 1: // 切后台
        switch (page) {
          case "kyc":
            uploadStat({
              type: "page2_background",
              startTime: reactLocalStorage.get("kycViewStartTime"),
              endTime: Date.now(),
            })
              .then()
              .catch()
            break
          case "info":
            uploadStat({
              type: "page3_background",
              startTime: reactLocalStorage.get("infoViewStartTime"),
              endTime: Date.now(),
            })
              .then()
              .catch()
            break
          case "contact":
            uploadStat({
              type: "page4_background",
              startTime: reactLocalStorage.get("contactViewStartTime"),
              endTime: Date.now(),
            })
              .then()
              .catch()
            break
          case "bank":
            uploadStat({
              type: "page5_background",
              startTime: reactLocalStorage.get("bankViewStartTime"),
              endTime: Date.now(),
            })
              .then()
              .catch()
            break
          case "confirm":
            uploadStat({
              type: "page6_background",
              startTime: reactLocalStorage.get("confirmViewStartTime"),
              endTime: Date.now(),
            })
              .then()
              .catch()
            break
        }
        pageBackTime.push({
          startTime: t || Date.now(),
        })
        break
      case 2: // 切前台
        if (pageBackTime.length > 0) {
          pageBackTime[pageBackTime.length - 1]["endTime"] = t || Date.now()
        }
        break
    }
    backTime[page] = pageBackTime
    reactLocalStorage.setObject("backTime", backTime)
  } catch (e) {
    console.log(e)
  }
}
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics, setUserProperties, logEvent } from "firebase/analytics"
import { getAesKey, uploadStat } from "./api/v2/loan"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDz3TV0aGYnxEqFSzQUqYlWR8abRKxCE3o",
  authDomain: "android01-eeed4.firebaseapp.com",
  projectId: "android01-eeed4",
  storageBucket: "android01-eeed4.appspot.com",
  messagingSenderId: "663048572285",
  appId: "1:663048572285:web:7c729fef0f06868c861014",
  measurementId: "G-VLPWKRG8FC",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
window.addEventListener("error", (err) => {
  logEvent(analytics, "flashb_debug_log", {
    level: "error",
    event: "error",
    screen_name: reactLocalStorage.get("currentPage"),
    message: JSON.stringify({
      message: err.message,
      fileName: err.filename,
      line: err.lineno,
      errNo: err.colno,
    }),
    X_DEVICE_NAME: getCookie("model"),
    appNme: getCookie("packageName"),
    version: getCookie("client_version"),
  })
})
window.addEventListener("unhandledrejection", (err) => {
  logEvent(analytics, "flashb_debug_log", {
    level: "error",
    event: "error",
    screen_name: reactLocalStorage.get("currentPage"),
    message: JSON.stringify({
      message: err.message,
      fileName: err.filename,
      line: err.lineno,
      errNo: err.colno,
      reason: err.reason,
    }),
    X_DEVICE_NAME: getCookie("model"),
    appNme: getCookie("packageName"),
    version: getCookie("client_version"),
  })
})
export default function App () {
  const [init, setInit] = useState(false)
  const [editTime, setEditTime] = useState({})
  const setFieldEditStartTime = (field) => {
    let tmp = editTime
    tmp[field] = Date.now()
    setEditTime(tmp)
  }
  const endEdit = (field) => {
    uploadStat({
      type: field,
      startTime: editTime[field] || Date.now(),
      endTime: Date.now(),
    })
      .then()
      .catch()
  }
  useEffect(() => {
    reactLocalStorage.clear()
    let aesKey = sessionStorage.getItem("aesKey")
    if (!aesKey) {
      getAesKey().then((res) => {
        if (res.code === 200) {
          sessionStorage.setItem("aesKey", JSON.stringify(res.data))
        }
        setInit(true)
      })
    } else {
      setInit(true)
    }
  }, [])
  return (
    <ConfigProvider locale={enUS}>
      <HashRouter>
        <AuthProvider>
          <Routes>
            <Route element={<Layout />}>
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Home analytics={analytics} />
                  </RequireAuth>
                }
              />
              <Route path="/login" element={<Login useAuth={useAuth} />} />
              <Route
                path="/login-sms"
                element={<LoginSms useAuth={useAuth} />}
              />
              <Route path="/privacy" element={<Privacy />} />
              <Route
                path="/home"
                element={
                  <RequireAuth>
                    <Home analytics={analytics} />
                  </RequireAuth>
                }
              />
              <Route
                path="/kyc"
                element={
                  <RequireAuth>
                    <Kyc analytics={analytics} />
                  </RequireAuth>
                }
              />
              <Route
                path="/reloan"
                element={
                  <RequireAuth>
                    <Reloan analytics={analytics} />
                  </RequireAuth>
                }
              />
              <Route
                path="/info"
                element={
                  <RequireAuth>
                    <Info analytics={analytics} />
                  </RequireAuth>
                }
              />
              <Route
                path="/contact"
                element={
                  <RequireAuth>
                    <Contact analytics={analytics} />
                  </RequireAuth>
                }
              />
              <Route
                path="/bank"
                element={
                  <RequireAuth>
                    <Bank analytics={analytics} />
                  </RequireAuth>
                }
              />
              <Route
                path="/face"
                element={
                  <RequireAuth>
                    <Face analytics={analytics} />
                  </RequireAuth>
                }
              />
              <Route
                path="/mine"
                element={
                  <RequireAuth>
                    <MineIndex analytics={analytics} />
                  </RequireAuth>
                }
              />
              <Route
                path="/confirm"
                element={
                  <RequireAuth>
                    <LoanConfirm analytics={analytics} />
                  </RequireAuth>
                }
              />
              <Route
                path="/modify-bank"
                element={
                  <RequireAuth>
                    <ModifyBank analytics={analytics} />
                  </RequireAuth>
                }
              />
              <Route
                path="/orders"
                element={
                  <RequireAuth>
                    <Orders analytics={analytics} />
                  </RequireAuth>
                }
              />
              <Route
                path="/feedback"
                element={
                  <RequireAuth>
                    <Feedback analytics={analytics} />
                  </RequireAuth>
                }
              />
              <Route
                path="/settings"
                element={
                  <RequireAuth>
                    <Settings analytics={analytics} />
                  </RequireAuth>
                }
              />
              <Route
                path="/protocols"
                element={<Protocols analytics={analytics} />}
              />
            </Route>
          </Routes>
        </AuthProvider>
        {init && (
          <AuthProviderV2>
            <MyComponent>
              <Routes>
                <Route element={<Layout />}>
                  <Route
                    path="/v2"
                    element={
                      <RequireAuthV2>
                        <HomeV2 analytics={analytics} />
                      </RequireAuthV2>
                    }
                  />
                  <Route
                    path="/v2/steps"
                    element={
                      <RequireAuthV2>
                        <StepsV2 analytics={analytics} />
                      </RequireAuthV2>
                    }
                  />
                  <Route
                    path="/v2/login"
                    element={<LoginV2 useAuth={useAuthV2} />}
                  />
                  <Route
                    path="/v2/login-sms"
                    element={<LoginSmsV2 useAuth={useAuthV2} />}
                  />
                  <Route path="/v2/privacy" element={<PrivacyV2 />} />
                  <Route path="/v2/protocols" element={<ProtocolsV2 />} />
                  <Route
                    path="/v2/home"
                    element={
                      <RequireAuthV2>
                        <HomeV2 analytics={analytics} />
                      </RequireAuthV2>
                    }
                  />
                  <Route
                    path="/v2/kyc"
                    element={
                      <RequireAuthV2>
                        <KycV2
                          beginEdit={setFieldEditStartTime}
                          endEdit={endEdit}
                          analytics={analytics}
                        />
                      </RequireAuthV2>
                    }
                  />
                  <Route
                    path="/v2/reloan"
                    element={
                      <RequireAuthV2>
                        <Reloan analytics={analytics} />
                      </RequireAuthV2>
                    }
                  />
                  <Route
                    path="/v2/info"
                    element={
                      <RequireAuthV2>
                        <InfoV2
                          beginEdit={setFieldEditStartTime}
                          endEdit={endEdit}
                          analytics={analytics}
                        />
                      </RequireAuthV2>
                    }
                  />
                  <Route
                    path="/v2/contact"
                    element={
                      <RequireAuthV2>
                        <ContactV2
                          beginEdit={setFieldEditStartTime}
                          endEdit={endEdit}
                          analytics={analytics}
                        />
                      </RequireAuthV2>
                    }
                  />
                  <Route
                    path="/v2/bank"
                    element={
                      <RequireAuthV2>
                        <BankV2
                          beginEdit={setFieldEditStartTime}
                          endEdit={endEdit}
                          analytics={analytics}
                        />
                      </RequireAuthV2>
                    }
                  />
                  <Route
                    path="/v2/mine"
                    element={
                      <RequireAuthV2>
                        <MineIndexV2 analytics={analytics} />
                      </RequireAuthV2>
                    }
                  />
                  <Route
                    path="/v2/confirm"
                    element={
                      <RequireAuthV2>
                        <LoanConfirmV2
                          beginEdit={setFieldEditStartTime}
                          endEdit={endEdit}
                          analytics={analytics}
                        />
                      </RequireAuthV2>
                    }
                  />
                  <Route
                    path="/v2/modify-bank"
                    element={
                      <RequireAuthV2>
                        <ModifyBankV2 analytics={analytics} />
                      </RequireAuthV2>
                    }
                  />
                  <Route
                    path="/v2/settings"
                    element={
                      <RequireAuthV2>
                        <SettingsV2 analytics={analytics} />
                      </RequireAuthV2>
                    }
                  />
                  <Route
                    path="/v2/review"
                    element={<ReviewV2 analytics={analytics} />}
                  />
                  <Route
                    path="/v2/reject"
                    element={<RefusedV2 analytics={analytics} />}
                  />
                  <Route
                    path="/v2/repay"
                    element={<RepayV2 analytics={analytics} />}
                  />
                </Route>
              </Routes>
            </MyComponent>
          </AuthProviderV2>
        )}
      </HashRouter>
    </ConfigProvider>
  )
}

function Layout (props) {
  return (
    <div>
      <Outlet />
    </div>
  )
}

let AuthContext = React.createContext(null)
let AuthContextV2 = React.createContext(null)

function AuthProvider ({ children }) {
  let [token, setToken] = React.useState(null)
  let [init, setInit] = useState(false)
  useEffect(() => {
    if (token === null) {
      // alert(document.cookie)
      console.log(document.cookie)
      // if (navigator.userAgent.indexOf("flashbee") !== -1) {
      token = getCookie("token")
      if (token) {
        setToken(token)
        localStorage.setItem("x-token", token)
      } else {
        token = localStorage.getItem("x-token")
        if (token) {
          setToken(token)
        }
      }
      if (token) {
        setUserProperties(analytics, {
          flashb_side: getCookie("packageName"),
          flashb_country: "in",
          flashb_logined: 1,
          flashb_black: 0,
          flashb_refuse: 0,
        })
      }
      setInit(true)
    }
  }, [])

  let signIn = (data, callback) => {
    return fakeAuthProvider.signIn(data, (res) => {
      console.log(res)
      if (res.code === 200) {
        setToken(res.data.sJdmAaTK)
        localStorage.setItem("x-token", res.data.sJdmAaTK)
        document.cookie = "token=" + res.data.sJdmAaTK + "; path=/"
      }
      callback(res)
    })
  }

  let signOut = (callback: VoidFunction) => {
    return fakeAuthProvider.signOut(() => {
      setToken(null)
      localStorage.removeItem("x-token")
      callback()
    })
  }

  let value = { signIn, signOut, token }

  return (
    <AuthContext.Provider value={value}>
      {init && children}
    </AuthContext.Provider>
  )
}

function AuthProviderV2 ({ children }) {
  const navi = useNavigate()
  let [token, setToken] = React.useState(null)
  let [init, setInit] = useState(false)
  let getToken = () => {
    return new Promise((resolve, reject) => {
      let getLoginInfo = (message) => {
        console.log('====================================')
        console.log('page get new login info :', message)
        console.log('====================================')
        let token = JSON.parse(message).token
        let appid = JSON.parse(message).AppId
        let phoneNum = JSON.parse(message).phoneNum
        // console.log('====================================')
        // console.log(JSON.parse(message))
        // console.log('====================================')
        setToken(token) // 更新 token 状态

        if (token) {
          localStorage.setItem('token', token)
        } else {
          token = null
        }
        if (appid) {
          localStorage.setItem('appid', appid)
        } else {
          appid = null
        }
        if (phoneNum) {
          localStorage.setItem('phoneNum', phoneNum)
        } else {
          phoneNum = null
        }

        resolve(token)
      }

      window.getLoginInfo = getLoginInfo
      window.webkit.messageHandlers.getLoginInfo.postMessage('getLoginInfo')
    })
  }
  useEffect(() => {

    let fetchData = async () => {
      try {
        let r = await getToken()
        setToken(r)
        // 在这里可以继续处理获取到的 token
        if (token === null) {
          // alert(document.cookie)
          console.log(document.cookie)
          // if (navigator.userAgent.indexOf("flashbee") !== -1) {
          token = getCookie("token")
          if (token) {
            setToken(token)
            localStorage.setItem("x-token", token)
          } else {
            token = localStorage.getItem("x-token")
            if (token) {
              setToken(token)
            }
          }
          if (token) {
            setUserProperties(analytics, {
              flashb_side: getCookie("packageName"),
              flashb_country: "in",
              flashb_logined: 1,
              flashb_black: 0,
              flashb_refuse: 0,
            })
          }
          setInit(true)
        }
      } catch (error) {
        console.error('获取 token 失败:', error)
      }
    }
    fetchData()

  }, [token])

  let signIn = (data, callback) => {
    return fakeAuthProvider.signInV2(data, (res) => {
      console.log(res)
      if (res.code === 200) {
        setToken(res.data.token)
        localStorage.setItem("x-token", res.data.token)
        document.cookie = "token=" + res.data.token + "; path=/"
        document.cookie = "phoneNum=" + res.data.customerName + "; path=/"
      }
      callback(res)
    })
  }

  let signOut = (callback: VoidFunction) => {
    return fakeAuthProvider.signOut(() => {
      setToken(null)
      localStorage.removeItem("x-token")
      callback()
    })
  }

  let value = { signIn, signOut, token }

  return (
    <AuthContextV2.Provider value={value}>
      {init && children}
    </AuthContextV2.Provider>
  )
}

function useAuth () {
  return React.useContext(AuthContext)
}

function useAuthV2 () {
  console.log("call use auth")
  return React.useContext(AuthContextV2)
}

function RequireAuth ({ children }) {
  let auth = useAuth()
  let location = useLocation()

  if (!auth.token) {
    window.location.href = "jzrhscheme://loginVC?action=loginVC"
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

function RequireAuthV2 ({ children }) {
  let auth = useAuthV2()
  let location = useLocation()

  if (!auth.token) {
    window.location.href = "jzrhscheme://loginVC?action=loginVC"
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/v2/login" state={{ from: location }} replace />
  }

  return children
}

function MyComponent ({ children }) {
  return children
  const location = useLocation()
  let auth = useAuthV2()
  useEffect(() => {
    if (auth.token) {
      isModel6666().then(async (res) => {
        let appId = getCookie("packageName")
        let newdata = res.data
        if (newdata.isOnline == 0 && appId == 'com.Mohd.FocusDigits') {
          const result = await Modal.confirm({
            content: newdata.textShow,
          })
          if (result) {
            window.location.href = 'itms-apps://itunes.apple.com/app/id6475383568'
          }

        }
      })
    }

    return () => {
      // 清理工作（可选）
    }
  }, [location]) // 将history添加为依赖项，确保只有在路由改变时才重新运行effect

  return children
}
