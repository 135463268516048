import {login} from "./api/login";
import {loginV2} from "./api/v2/loan";

/**
 * This represents some generic auth provider API, like Firebase.
 */
const fakeAuthProvider = {
  isAuthenticated: false,
  signIn(data, callback) {
    console.log(data, callback)
    login(data).then(res => {
      console.log('-----------------',res);
      if (res.code === 200) {
        fakeAuthProvider.isAuthenticated = true;
      }
      callback(res);
    }).catch(e => {
      console.log(e, callback)
      callback(e)
    });
  },
  signOut(callback) {
    fakeAuthProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
  signInV2(data, callback) {
    console.log(data, callback)
    loginV2(data).then(res => {
      console.log('-----------------',res);
      if (res.code === 200) {
        fakeAuthProvider.isAuthenticated = true;
      }
      callback(res);
    }).catch(e => {
      console.log(e, callback)
      callback(e)
    });
  },
  signOutV2(callback) {
    fakeAuthProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};

export { fakeAuthProvider };
