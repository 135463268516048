import request from "./request"
import { insertRandomNumbers } from "../../utils/tools"

export function getAesKey (data) {
  return request({
    url: insertRandomNumbers("ZYjbbYkIT"),
    headers: {
      isToken: false,
      noAes: true,
    },
    method: "post",
    data: data,
  })
}

export function sendLoginSms (data) {
  return request({
    url: insertRandomNumbers("VIhfAUQmUNF"),
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  })
}


export function loginV2 (data) {
  return request({
    url: insertRandomNumbers("YMFHMMBAYT"),
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  })
}


export function getProductList (data) {
  return request({
    url: insertRandomNumbers("YtXnLsrfx"),
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  })
}


export function getLoanProcess (data) {
  return request({
    url: insertRandomNumbers("GNEAsyHIGDl"),
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  })
}


export function saveKycInfo (data) {
  return request({
    url: insertRandomNumbers("JtyIcfHFJWG"),
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  })
}


export function uploadStat (data) {
  return request({
    url: insertRandomNumbers("POINTBYUSERNUM"),
    headers: {
      isToken: true,
    },
    method: "post",
    data: { ...data, noLoading: true, },
  })
}


export function getApplyInfo (data) {
  return request({
    url: insertRandomNumbers("UVCRLMLSpI"),
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  })
}


export function getApplyImages (data) {
  return request({
    url: insertRandomNumbers("IODEujKFGD"),
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  })
}


export function getDict (data) {
  return request({
    url: insertRandomNumbers("lGHaBjYHDH"),
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  })
}

export function savePersonalInfo (data) {
  return request({
    url: insertRandomNumbers("MuDKQJSuiMf"),
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  })
}

export function saveContact (data) {
  return request({
    url: insertRandomNumbers("MuDKQJSuiMf"),
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  })
}


export function saveBank (data) {
  return request({
    url: insertRandomNumbers("HUPJwIJIDjf"),
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  })
}


export function getBank (data) {
  return request({
    url: insertRandomNumbers("IFvZlByIrB"),
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  })
}

export function modifyBank (data) {
  return request({
    url: insertRandomNumbers("YPMBEwKldDY"),
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  })
}


export function removeAccount (data) {
  return request({
    url: insertRandomNumbers("ggYyvWhKOP"),
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  })
}


export function getConfirmInfo (data) {
  return request({
    url: insertRandomNumbers("EyUMfUJTU"),
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  })
}


export function confirmInfo (data) {
  return request({
    url: insertRandomNumbers("jkKIdihsihFH"),
    headers: {
      isToken: true,
      noAes: true,
    },
    method: "post",
    data: data,
  })
}

export function getCustomerInfo (data) {
  return request({
    url: insertRandomNumbers("IkMtykISH"),
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  })
}

export function getOrderDetail (data) {
  return request({
    url: insertRandomNumbers("KLPPVPGrnET"),
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  })
}


export function getPaymentLink (data) {
  return request({
    url: insertRandomNumbers("MKFEwTDYBH"),
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  })
}
export function getReportPoint () {
  return request({
    url: insertRandomNumbers("JqVUOraCObHc"),
    headers: {
      isToken: true,
    },
    method: "get",
  })
}

// 是否弹框接口
export function isModel6666 () {
  return request({
    url: "/iXoNfdUYVr",
    headers: {
      isToken: true,
    },
    method: "post",
  })
}
export function getAddress (data) {
  return request({
    url: "/dXopfdtexr",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  })
}