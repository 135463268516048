import "../../assets/less/footer.less";
import home from "../../assets/images/icon-home.webp";
import homeInactive from "../../assets/images/icon-home-inactive.webp";
import mime from "../../assets/images/icon-my.webp";
import mimeInactive from "../../assets/images/icon-my-inactive.webp";
import {useNavigate} from "react-router-dom";
export default function FooterV2({current}) {
  const navi = useNavigate();
  return (
    <>
      <div className={current === 'home' ? "footer index" : "footer my"}>
        {
          current === 'home' &&
          <>
            <div className={"item"} onClick={() => {
              // navi("/home");
            }}>
              <img src={home} alt={""} />
            </div>
            <div className={"item mine"} onClick={() => {
              navi("/v2/mine");
            }}>
              <img src={mimeInactive} alt={""} />
            </div>
          </>
        }
        {
          current !== 'home' &&
          <>
            <div className={"item"} onClick={() => {
              navi("/v2/home");
            }}>
              <img src={homeInactive} alt={""} />
            </div>
            <div className={"item mine"} onClick={() => {
              // navi("/mine");
            }}>
              <img src={mime} alt={""} />
            </div>
          </>
        }
      </div>
    </>
  )
}
