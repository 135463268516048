import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {compareVersion, getCookie} from "../../../utils/tools";
import logo from "../../../assets/images/home_logo.webp";
import help from "../../../assets/images/icon-help.webp";
import "../../../assets/less/mine.less";
import menuAgreement from "../../../assets/images/icon-agreement.webp";
import menuSetting from "../../../assets/images/icon-setting.webp";
import arrow from "../../../assets/images/icon-arrow-right.webp";
import FooterV2 from "../../../components/footer/indexv2";
import {getCustomerInfo} from "../../../api/v2/loan";

export default function MineIndex() {
  const [t] = useTranslation();
  const navi = useNavigate();
  const [init, setInit] = useState(false)
  const [phone, setPhone] = useState("");
  const [customLink, setCustomLink] = useState("")
  useEffect(() => {
    let p =localStorage.getItem('phoneNum')
    p = p.substring(0,3) + "****" + p.substring(p.length-4)
    setPhone(p);
    if (getCookie("model") === "android") {
      getCustomerInfo({
        MsEAjMV: 2
      }).then(res => {
        setCustomLink(res?.data?.customer_serviceLink)
      })
    } else {
      getCustomerInfo({
        MsEAjMV: 3
      }).then(res => {
        setCustomLink(res?.data?.customer_serviceLink)
      })
    }
    setInit(true)
  }, []);
  return (<>
    <div className={"mine-container"}>
      <div className={"logo"}>
        <img className={"logo-img"} src={logo} alt="" />
        <img className={"help"} src={help} alt="" onClick={() => {
          let ver = getCookie('client_version')
          if (getCookie("model") !== "android") {
            window.location.href = "jzrhscheme://openWebVC?action=openWebVC&url="+encodeURIComponent(customLink)
          } else {
            window.location.href = customLink;
          }
        }}  />
      </div>
      <div className={"phone"}>{phone}</div>
      <div className={"menus"} style={{marginTop: ".6rem"}}>
        <div className={"item"} onClick={()=>{
          navi("/v2/protocols")
        }}>
          <img src={menuAgreement} className={"icon"} alt={""} />
          <div className={"text"}>{t('mine_menu_agreement')}</div>
          <img src={arrow} className={"arrow"} alt={""} />
        </div>
        <div className={"item"} onClick={() => {
          navi("/v2/settings")
        }}>
          <img src={menuSetting} className={"icon"} alt={""} />
          <div className={"text"}>{t('mine_menu_setting')}</div>
          <img src={arrow} className={"arrow"} alt={""} />
        </div>
      </div>
    </div>
    <FooterV2 current={"mine"}></FooterV2>
  </>)
}
