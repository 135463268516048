import Footer from "../../components/footer";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {compareVersion, getCookie} from "../../utils/tools";
import logo from "../../assets/images/home_logo.webp";
import help from "../../assets/images/icon-help.webp";
import "../../assets/less/mine.less";
import iconAll from "../../assets/images/icon-my-orders.webp";
import iconOutstanding from "../../assets/images/icon-my-outstanding.webp";
import iconOverdue from "../../assets/images/icon-overdue.webp";
import iconSettled from "../../assets/images/icon-settled.webp";
import menuRepay from "../../assets/images/icon-repayment.webp";
import menuFeedback from "../../assets/images/icon-feedback.webp";
import menuCupones from "../../assets/images/icon-cupones.webp";
import menuAgreement from "../../assets/images/icon-agreement.webp";
import menuAbout from "../../assets/images/icon-about.webp";
import menuSetting from "../../assets/images/icon-setting.webp";
import arrow from "../../assets/images/icon-arrow-right.webp";
import {getOnlineCustomService} from "../../api/loan";

export default function MineIndex() {
  const [t] = useTranslation();
  const navi = useNavigate();
  const loc = useLocation();
  const [init, setInit] = useState(false)
  const [phone, setPhone] = useState("");
  const [customLink, setCustomLink] = useState("")
  useEffect(() => {
    let p = getCookie("phoneNum")
    p = p.substring(0,3) + "****" + p.substring(p.length-4)
    setPhone(p);
    if (getCookie("model") === "android") {
      getOnlineCustomService({
        MsEAjMV: 2
      }).then(res => {
        setCustomLink(res?.data)
      }).catch(err=>{
        console.log('====================================');
        console.log(err);
        console.log('====================================');
      })
    } else {
      getOnlineCustomService({
        MsEAjMV: 3
      }).then(res => {
        setCustomLink(res?.data)
      }).catch(err=>{
        console.log('====================================');
        console.log(err);
        console.log('====================================');
      })
    }
    setInit(true)
  }, []);
  return (<>
    <div className={"mine-container"}>
      <div className={"logo"}>
        <img className={"logo-img"} src={logo} alt="" />
        <img className={"help"} src={help} alt="" onClick={() => {
          let ver = getCookie('client_version')
          if (getCookie("model") !== "android" && compareVersion(ver, "1.0.1") === 1) {
            window.location.href = customLink
          } else {
            window.location.href = customLink;
          }
        }}  />
      </div>
      <div className={"phone"}>{phone}</div>
      <div className={"orders-nav"}>
        <div className={"item"} onClick={()=>{
          navi("/orders", {state: {type:'0'}})
        }}>
          <img src={iconAll} alt={""} />
          <div>{t('mine_all_orders')}</div>
        </div>
        <div className={"item"} onClick={()=>{
          navi("/orders", {state: {type:'1'}})
        }}>
          <img src={iconOutstanding} alt={""} />
          <div>{t('mine_outstanding')}</div>
        </div>
        <div className={"item"} onClick={()=>{
          navi("/orders", {state: {type:'2'}})
        }}>
          <img src={iconOverdue} alt={""} />
          <div>{t('mine_overdue')}</div>
        </div>
        <div className={"item"} onClick={()=>{
          navi("/orders", {state: {type:'3'}})
        }}>
          <img src={iconSettled} alt={""} />
          <div>{t('mine_settled')}</div>
        </div>
      </div>
      <div className={"menus"}>
        {/*<div className={"item"}>*/}
        {/*  <img src={menuRepay} className={"icon"} alt={""} />*/}
        {/*  <div className={"text"}>{t('mine_menu_repay')}</div>*/}
        {/*  <img src={arrow} className={"arrow"} alt={""} />*/}
        {/*</div>*/}
        <div className={"item"} onClick={() => {
          navi("/feedback")
        }}>
          <img src={menuFeedback} className={"icon"} alt={""} />
          <div className={"text"}>{t('mine_menu_feedback')}</div>
          <img src={arrow} className={"arrow"} alt={""} />
        </div>
        {/*<div className={"item"}>*/}
        {/*  <img src={menuCupones} className={"icon"} alt={""} />*/}
        {/*  <div className={"text"}>{t('mine_menu_cupones')}</div>*/}
        {/*  <img src={arrow} className={"arrow"} alt={""} />*/}
        {/*</div>*/}
        <div className={"item"} onClick={()=>{
          navi("/protocols")
        }}>
          <img src={menuAgreement} className={"icon"} alt={""} />
          <div className={"text"}>{t('mine_menu_agreement')}</div>
          <img src={arrow} className={"arrow"} alt={""} />
        </div>
        {/*<div className={"item"}>*/}
        {/*  <img src={menuAbout} className={"icon"} alt={""} />*/}
        {/*  <div className={"text"}>{t('mine_menu_about')}</div>*/}
        {/*  <img src={arrow} className={"arrow"} alt={""} />*/}
        {/*</div>*/}
        <div className={"item"} onClick={() => {
          navi("/settings")
        }}>
          <img src={menuSetting} className={"icon"} alt={""} />
          <div className={"text"}>{t('mine_menu_setting')}</div>
          <img src={arrow} className={"arrow"} alt={""} />
        </div>
      </div>
    </div>
    <Footer current={"mine"}></Footer>
  </>)
}
