import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import './i18n'
import 'antd-mobile/es/global'
import { DotLoading } from "antd-mobile"
import Vconsole from 'vconsole'
// new Vconsole();
console.log(`${process.env.REACT_ENV}`)
if (`${process.env.REACT_APP_ENV}` === 'testing') {
  new Vconsole()
}
// import './views/vconsole'
document.addEventListener('DOMContentLoaded', function () {
  ReactDOM.createRoot(
    document.getElementById('root')
  ).render(<Suspense fallback={<div className="my-loading-container" id="myLoadingContainer">
    <span
      style={{ fontSize: ".32rem", background: "#ffffff", borderRadius: ".06rem", padding: ".32rem .12rem" }}><DotLoading
        color={"#1DCFB7"} /></span>
  </div>}>
    <App />
  </Suspense>)
});

