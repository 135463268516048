import Footer from "../../components/footer/index"
import "../../assets/less/home.less"
import logo from "../../assets/images/home_logo.webp"
import help from "../../assets/images/icon-help.webp"
import { useEffect, useRef, useState } from "react"

import welcomeArrow from "../../assets/images/welcome-arrow.webp"
import welcomeApply from "../../assets/images/welcome-apply.webp"
import underReview from "../../assets/images/home-under-review.webp"
import extensionTip from "../../assets/images/icon-extension.webp"
import { useTranslation } from "react-i18next"
import { checkToken, getOnlineCustomService, getProductInfo, getRepayLink } from "../../api/loan"
import { Modal, PullToRefresh, Toast } from "antd-mobile"
import { compareVersion, getCookie, number_format } from "../../utils/tools"
import { useNavigate } from "react-router-dom"
import { logEvent } from "firebase/analytics"
import { reactLocalStorage } from "reactjs-localstorage"

export default function Home (props) {
  // 获取text-content结点
  const textContentRef = useRef(null)
  const textContentWrapperRef = useRef(null)
  const [t] = useTranslation()
  const navi = useNavigate()

  // 声明间隔
  const [duration, setDuration] = useState('')
  const [isNewUser, setIsNewUser] = useState(false)
  const [newUserText, setNewUserText] = useState("")
  const [productInfo, setProductInfo] = useState({})
  const [isShowTip, setIsShowTip] = useState(false)
  const [tip, setTip] = useState("")
  const [speedUpTimes, setSpeedUpTimes] = useState(0)
  const [phone, setPhone] = useState("")
  useEffect(() => {
    document.title = "home"
    reactLocalStorage.set("currentPage", "home")
    let p = getCookie("phoneNum")
    setPhone(p)
    console.log(getCookie("isNewUser"), localStorage.getItem("isNewUser" + p))
    setIsNewUser(!!getCookie("isNewUser") && !getCookie("isNewUser" + p))
    setNewUserText(getCookie("newUserText"))
    const textContent = textContentRef.current
    if (textContent) {
      // 获取实际宽度
      const { width } = textContent.getBoundingClientRect()
      // console.log(textContentWrapperRef.current.clientWidth, width)
      if (width > textContentWrapperRef.current.clientWidth) {
        setDuration(width / 8 + 's')
        console.log(duration)
      } else {
        setDuration('')
      }
    } else {
      setDuration('')
    }
    logEvent(props.analytics, "page_view", {
      page_title: reactLocalStorage.get("currentPage"),
      page_path: "/home",
      page_location: window.location.href,
      page_referrer: "",
    })
    checkToken({}).then((res) => {
      if (res && res.code === 200 && res.data.UpUzrSh === "1") {
        navi("/v2/home")
      }
    }).catch(err => {
      console.log('====================================')
      console.log(err)
      console.log('====================================')
    })
  }, [])
  const [init, setInit] = useState(false)
  const [customLink, setCustomLink] = useState("")
  useEffect(() => {
    getProductInfo({
      amvzsNBE: "com.Mohd.FocusDigits"
    }).then((res) => {
      let d = res.data
      setProductInfo(d)
      setInit(true)
      // console.log(res);
    }).catch(() => {

    })
    if (getCookie("model") === "android") {
      getOnlineCustomService({
        MsEAjMV: 2
      }).then(res => {
        setCustomLink(res?.data)
      }).catch((e) => {
        console.log(e)
      })
    } else {
      getOnlineCustomService({
        MsEAjMV: 3
      }).then(res => {
        setCustomLink(res?.data)
      }).catch((e) => {
        console.log(e)
      })
    }
    const interval = setInterval(() => {
      getProductInfo({
        amvzsNBE: "com.Mohd.FocusDigits",
        noLoading: true,
      }).then((res) => {
        let d = res.data
        setProductInfo(d)
        setInit(true)
      }).catch(() => {

      })
    }, 90000)
    return () => {
      clearInterval(interval)
    }
  }, [])
  return (<>
    <PullToRefresh
      onRefresh={() => {
        getProductInfo({
          amvzsNBE: "com.Mohd.FocusDigits"
        }).then((res) => {
          let d = res.data
          setProductInfo(d)
          console.log(res)
        }).catch(() => {

        })
      }}
    >
      <div className={"home"}>
        <div className={"nav"}></div>
        <div className={"top"}>
          <img className={"logo"} src={logo} alt="" />
          <img className={"help"} src={help} alt="" onClick={() => {
            let ver = getCookie('client_version')
            if (getCookie("model") !== "android" && compareVersion(ver, "1.0.1") === 1) {
              window.location.href = customLink
            } else {
              window.location.href = customLink
            }
          }} />
        </div>
        <div className={"alert"}>
          <div className={"icon"}>
          </div>
          <div className={"text"} ref={textContentWrapperRef}>
            <div className={"text-content"} ref={textContentRef}
              style={{
                animationDuration: duration,
                animationDelay: duration ? `0s, ${duration}` : ''
              }}> {productInfo.mainProduct?.ffghelH}</div>
          </div>
        </div>
        {
          init && productInfo.mainProduct?.kqRLViE === 1 &&
          <div className={"main-product"}>
            <div className={"product-name"}>
              <div className={"product-image"}><img src={productInfo.mainProduct?.cCpiYAB} alt="" /></div>
              <div className={"text"}>{productInfo.mainProduct?.FaFsqGY}</div>
            </div>
            <div className={"under-review-image"}>
              <img src={underReview} alt="" />
            </div>
            <div className={"under-review-text"}>{t("home_under_review")}</div>
            <div className={"button"} onClick={() => {
              setSpeedUpTimes(speedUpTimes => speedUpTimes + 1)
              Modal.alert({
                content: speedUpTimes > 0 ? t('home_speed_up_review_text_b') : t('home_speed_up_review_text_a'),
                confirmText: "Confirm",
                cancelText: "Cancel",
              })
            }}>{t('home_speed_up_review')}</div>
            <div className={"rate"}>{t('home_leave_a_positive_review')}</div>
          </div>
        }
        {
          init && productInfo.mainProduct?.kqRLViE === 3 &&
          <div className={"main-product"}>
            <div className={"product-name"}>
              <div className={"product-image"}><img src={productInfo.mainProduct?.cCpiYAB} alt="" /></div>
              <div className={"text"}>{productInfo.mainProduct?.FaFsqGY}</div>
            </div>
            <div className={"amount-title"}>{t("home_loan_amount")}</div>
            <div className={"amount"}>₹{number_format(productInfo.mainProduct?.tknTcKS, 0)}</div>
            <div className={"period"}>{t("home_tenure")} <span
              className={"num"}> {productInfo.mainProduct?.ckyFRGx} </span> {t("home_days")}</div>
            <div className={"bank"}>{t("home_bank_account")} <span
              className={"num"}> {productInfo.mainProduct?.hZEUFth}</span></div>
            <div className={"button"} onClick={() => {
              setSpeedUpTimes(speedUpTimes => speedUpTimes + 1)
              Modal.alert({
                content: speedUpTimes > 0 ? t('home_speed_up_disbursement_text_b') : t('home_speed_up_disbursement_text_a'),
                confirmText: "Confirm",
                cancelText: "Cancel",
              })
            }}>{t('home_speed_up_disbursement')}</div>
            <div className={"rate"}>{t('home_leave_a_positive_review')}</div>
          </div>
        }
        {
          init && productInfo.mainProduct?.kqRLViE === 7 &&
          <div className={"main-product"}>
            <div className={"product-name"}>
              <div className={"product-image"}><img src={productInfo.mainProduct?.cCpiYAB} alt="" /></div>
              <div className={"text"}>{productInfo.mainProduct?.FaFsqGY}</div>
            </div>
            <div className={"amount-title"}>{t("home_loan_amount")}</div>
            <div className={"amount"}>₹{number_format(productInfo.mainProduct?.tknTcKS, 0)}</div>
            <div className={"period"}>{t("home_tenure")} <span
              className={"num"}> {productInfo.mainProduct?.ThUvjBy} </span> {t("home_days")}</div>
            <div className={"bank"}>{t("home_bank_account")} <span
              className={"num"}> {productInfo.mainProduct?.hZEUFth}</span></div>
            <div className={"button"} onClick={() => {
              navi("/confirm", {
                state: {
                  productId: productInfo.mainProduct?.UdMdOmS,
                  amount: productInfo.mainProduct?.tknTcKS,
                  tenure: productInfo.mainProduct?.ckyFRGx
                }
              })
            }}>{t('home_withdraw_now')}</div>
            <div className={"rate"}></div>
          </div>
        }
        {
          init && productInfo.mainProduct?.kqRLViE === 4 &&
          <div className={"main-product"}>
            <div className={"product-name"}>
              <div className={"product-image"}><img src={productInfo.mainProduct?.cCpiYAB} alt="" /></div>
              <div className={"text"}>{productInfo.mainProduct?.FaFsqGY}</div>
            </div>
            <div className={"amount-title"}>{t("home_loan_amount")}</div>
            <div className={"amount"}>₹{number_format(productInfo.mainProduct?.tknTcKS, 0)}</div>
            <div className={"period"}>{t("home_tenure")} <span
              className={"num"}> {productInfo.mainProduct?.ckyFRGx} </span> {t("home_days")}</div>
            <div className={"bank"}>{t("home_bank_account")} <span
              className={"num"}> {productInfo.mainProduct?.hZEUFth}</span></div>
            <div className={"button"} onClick={() => {
              navi("/modify-bank", {
                state: {
                  productId: productInfo.mainProduct?.UdMdOmS,
                  amount: productInfo.mainProduct?.tknTcKS,
                  tenure: productInfo.mainProduct?.ckyFRGx
                }
              })
            }}>{t('home_modify_bank_account')}</div>
            <div className={"rate"}>{t('home_leave_a_positive_review')}</div>
          </div>
        }
        {
          init && productInfo.mainProduct?.kqRLViE === 5 &&
          <div className={"main-product"}>
            <div className={"product-name"}>
              <div className={"product-image"}><img src={productInfo.mainProduct?.cCpiYAB} alt="" /></div>
              <div className={"text"}>{productInfo.mainProduct?.FaFsqGY}</div>
              {productInfo.mainProduct?.DAdkTMi === 1 && productInfo.mainProduct?.dxOiYvZ === 0 &&
                <div className={"due-days"}>{t('home_due_today')}</div>
              }
              {productInfo.mainProduct?.DAdkTMi === 1 && productInfo.mainProduct?.dxOiYvZ === 1 &&
                <div className={"due-days"}>{t('home_due_in')}<span
                  className={"num"}>{productInfo.mainProduct?.dxOiYvZ}</span>{t('home_day')}</div>
              }
              {productInfo.mainProduct?.DAdkTMi === 1 && productInfo.mainProduct?.dxOiYvZ > 1 &&
                <div className={"due-days"}>{t('home_due_in')}<span
                  className={"num"}>{productInfo.mainProduct?.dxOiYvZ}</span>{t('home_days')}</div>
              }
              {productInfo.mainProduct?.DAdkTMi === 0 && productInfo.mainProduct?.dxOiYvZ === 1 &&
                <div className={"due-days"}><span
                  className={"num"}>{productInfo.mainProduct?.dxOiYvZ}</span>{t('home_day')} {t('home_overdue')}</div>
              }
              {productInfo.mainProduct?.DAdkTMi === 0 && productInfo.mainProduct?.dxOiYvZ > 1 &&
                <div className={"due-days"}><span
                  className={"num"}>{productInfo.mainProduct?.dxOiYvZ}</span>{t('home_days')} {t('home_overdue')}</div>
              }
            </div>
            <div className={"amount-title"}>{t("home_repayment_due")}</div>
            <div className={"amount"}>₹{number_format(productInfo.mainProduct?.jssXUCV, 0)}</div>
            <div className={"due-date"}>{t("home_due_date")} {productInfo.mainProduct?.IpyXcdU} </div>
            <div className={"button-repay"} onClick={() => {
              getRepayLink({
                QEJLvVNn: productInfo.mainProduct?.eDYwuOy,
                aUEolrKf: 1,
                sdjtTfUP: productInfo.mainProduct?.jssXUCV
              }).then((res) => {
                let ver = getCookie('client_version')
                if (res.code === 200) {
                  if (getCookie("model") !== "android" && compareVersion(ver, "1.0.1") === 1) {
                    window.location.href = res.data
                    window.location.href = "jzrhscheme://openWebVC?action=openWebVC&url=" + encodeURIComponent(res.data)
                  } else {
                    window.location.href = res.data
                  }
                } else {
                  Toast.show({ content: res.message })
                }
              })
            }}>{t('home_full_repayment')} ₹{number_format(productInfo.mainProduct?.jssXUCV, 0)}</div>
            <div className={"button-extension"} onClick={() => {
              getRepayLink({
                QEJLvVNn: productInfo.mainProduct?.eDYwuOy,
                aUEolrKf: 2,
                sdjtTfUP: productInfo.mainProduct?.yFNDSLO
              }).then((res) => {
                let ver = getCookie('client_version')
                if (res.code === 200) {
                  if (getCookie("model") !== "android" && compareVersion(ver, "1.0.1") === 1) {
                    window.location.href = "jzrhscheme://openWebVC?action=openWebVC&url=" + encodeURIComponent(res.data)
                  } else {
                    window.location.href = res.data
                  }
                } else {
                  Toast.show({ content: res.message })
                }
              })
            }}><img src={extensionTip} alt="" className={"tip"} onClick={(e) => {
              e.stopPropagation()
              Toast.show({ content: t('home_extension_tip') })
            }} /> {t('home_extension')} ₹{number_format(productInfo.mainProduct?.yFNDSLO, 0)}</div>
          </div>
        }
        {
          init && productInfo.mainProduct?.kqRLViE === 6 &&
          <div className={"main-product"}>
            <div className={"product-name"}>
              <div className={"loan-amount"}>{t('home_loan_amount')}</div>
              <div className={"product-image"}><img src={productInfo.mainProduct?.cCpiYAB} alt="" /></div>
              <div className={"text"} style={{ paddingRight: ".16rem" }}>{productInfo.mainProduct?.FaFsqGY}</div>
            </div>
            <div className={"prices"}>
              {
                productInfo?.mainProduct?.productPriceList?.map((item, key) => {
                  return <div onClick={() => {
                    console.log(item)
                    if (item.PltNqHb === '1') {
                      let tmp = JSON.parse(JSON.stringify(productInfo))
                      tmp.mainProduct.productPriceList.forEach((ii) => {
                        ii["ryRRKmc"] = 0
                      })
                      tmp.mainProduct.productPriceList[key]["ryRRKmc"] = 1
                      // tmp.mainProduct.productPriceList[key]["PltNqHb"] = 0;
                      console.log(tmp.mainProduct.productPriceList)
                      setProductInfo(productInfo => tmp)
                    }
                  }}
                    className={item.PltNqHb !== '1' ? "item disabled" : (+item.ryRRKmc === 1 ? "item selected" : "item unselected")}
                    key={key}>{number_format(item.HSwIloC, 0)}</div>
                })
              }
            </div>
            <div className={"days"}>
              <div className={"label"}>{t("home_tenure")}</div>
              <div className={"items"}>
                {
                  productInfo?.mainProduct?.productDaysList?.map((item, key) => {
                    return <div onClick={() => {
                      if (item.CubKeQC === '1') {
                        let tmp = JSON.parse(JSON.stringify(productInfo))
                        tmp.mainProduct.productDaysList.forEach((ii) => {
                          ii["uSggaNO"] = 0
                        })
                        tmp.mainProduct.productDaysList[key]["uSggaNO"] = 1
                        console.log(tmp.mainProduct.productDaysList)
                        setProductInfo(productInfo => tmp)
                      }
                    }}
                      className={item.CubKeQC !== '1' ? "item disabled" : (+item.uSggaNO === 1 ? "item selected" : "item unselected")}
                      key={key}>{item.GPmCPci} {t("home_days")}</div>
                  })
                }
              </div>
            </div>
            <div className={"button-withdraw"} onClick={() => {
              let amount = 0
              productInfo?.mainProduct?.productPriceList?.forEach((item) => {
                if (+item.ryRRKmc === 1) {
                  amount = item.HSwIloC
                }
              })
              let tenure
              productInfo?.mainProduct?.productDaysList?.forEach((item) => {
                if (+item.uSggaNO === 1) {
                  tenure = item.GPmCPci
                }
              })
              navi("/confirm", {
                state: {
                  productId: productInfo.mainProduct?.UdMdOmS,
                  amount: amount,
                  tenure: tenure,
                  fj: 1,
                }
              })
            }}>{t('home_withdraw_now')}</div>
            <div className={"protocol"}>
              <div>{t("home_agree_protocol")}</div>
              <div className={"name"}>{t('home_protocol_name')}</div>
            </div>
          </div>
        }
        {
          init && productInfo.mainProduct?.kqRLViE === 2 &&
          <div className={"main-product"}>
            <div className={"reject-reapply-days"}>
              {t("home_reject_reapply", { days: productInfo.mainProduct?.PRTASmo })}
            </div>
            <div className={"reject-improving-credit"}>{t("home_reject_improving_credit")}</div>
            <div className={"button-reject"}>{t('home_reject_button')}</div>
          </div>
        }
        {
          init && productInfo.mainProduct && productInfo.mainProduct.kqRLViE === 8 &&
          <div className={"main-product"}>
            <div className={"product-name"}>
              <div className={"product-image"}><img src={productInfo.mainProduct?.cCpiYAB} alt="" /></div>
              <div className={"text"}>{productInfo.mainProduct?.FaFsqGY}</div>
            </div>
            <div className={"amount-title"}>{t("home_max_loan_amount")}</div>
            <div className={"amount"}>₹{number_format(productInfo.mainProduct?.pFCSRSf, 0)}</div>
            <div className={"period"}>{t("home_tenure")} <span
              className={"num"}> {productInfo.mainProduct?.ThUvjBy} - {productInfo.mainProduct?.sGHxjrm} </span> {t("home_days")}
            </div>
            <div className={"bank"}>{t("home_daily_interest")} <span
              className={"num"}> {productInfo.mainProduct?.DqJtHKb}%</span></div>
            <div className={"button"} onClick={() => {

            }}>{t('Pending unlock')}</div>
          </div>
        }
        {
          init && productInfo.mainProduct && (productInfo.mainProduct.kqRLViE === null || !productInfo.mainProduct.kqRLViE) &&
          <div className={"main-product"}>
            <div className={"product-name"}>
              <div className={"product-image"}><img src={productInfo.mainProduct?.cCpiYAB} alt="" /></div>
              <div className={"text"}>{productInfo.mainProduct?.FaFsqGY}</div>
            </div>
            <div className={"amount-title"}>{t("home_max_loan_amount")}</div>
            <div className={"amount"}>₹{number_format(productInfo.mainProduct?.pFCSRSf, 0)}</div>
            <div className={"period"}>{t("home_tenure")} <span
              className={"num"}> {productInfo.mainProduct?.ThUvjBy} - {productInfo.mainProduct?.sGHxjrm} </span> {t("home_days")}
            </div>
            <div className={"bank"}>{t("home_daily_interest")} <span
              className={"num"}> {productInfo.mainProduct?.DqJtHKb}%</span></div>
            <div className={"button"} onClick={() => {
              switch (productInfo.QiEleUB) {
                case 1:
                  navi("/info", { state: { productId: productInfo.mainProduct.UdMdOmS } })
                  break
                case 2:
                  navi("/contact", { state: { productId: productInfo.mainProduct.UdMdOmS } })
                  break
                case 3:
                  navi("/bank", { state: { productId: productInfo.mainProduct.UdMdOmS } })
                  break
                case 4:
                  navi("/face", { state: { productId: productInfo.mainProduct.UdMdOmS } })
                  break
                default:
                  navi("/kyc", { state: { productId: productInfo.mainProduct.UdMdOmS } })
                  break
              }
            }}>{productInfo.QiEleUB ? t('home_continue_application') : t('home_start_application')}</div>
          </div>
        }
        {
          init &&
          <div className={"banner"}>
            <img src={productInfo.mainProduct?.qvLjFuW} alt="" />
          </div>
        }
        {/*{*/}
        {/*  init && (productInfo.mainProduct?.kqRLViE === null || !productInfo.mainProduct?.kqRLViE) &&*/}
        {/*  <div className={"step"}>*/}
        {/*    <img src={homeStep} alt=""/>*/}
        {/*  </div>*/}
        {/*}*/}
        {
          init && productInfo.secondaryProduct.map((item, key) => {
            return <div key={key}>
              {
                item.CvyoEsE === 1 &&
                <div className={"more-product"}>
                  <div className={"title-row"}>
                    <img className={"icon"} src={item.ukGVFkv} alt={""} />
                    <div className={"text"}>
                      <div className={"product-name"}>
                        {item.fttlALU}
                      </div>
                      <div className={"desc"}>
                        {t('home_under_review')}
                      </div>
                    </div>
                    <div className={"more"}>
                      {t("home_tenure")} <span
                        className={"num"}> {item.GFRghfN} </span> {t("home_days")}
                    </div>
                  </div>
                  <div className={"amount-row"}>
                    <div className={"text"}>
                      <div className={"tt"}>{t('home_loan_amount')}</div>
                      <div className={"amount"}>₹{number_format(item.golQjjt, 0)}</div>
                    </div>
                    <div className={"button"} onClick={() => {
                      setSpeedUpTimes(speedUpTimes => speedUpTimes + 1)
                      Modal.alert({
                        content: speedUpTimes > 0 ? t('home_speed_up_review_text_b') : t('home_speed_up_review_text_a'),
                        confirmText: "Confirm",
                        cancelText: "Cancel",
                      })
                    }}>{t('home_speed_up_review')}</div>
                  </div>
                </div>
              }
              {
                item.CvyoEsE === 2 &&
                <div className={"more-product"}>
                  <div className={"title-row"}>
                    <img className={"icon"} src={item.ukGVFkv} alt={""} />
                    <div className={"text"}>
                      <div className={"product-name"}>
                        {item.fttlALU}
                      </div>
                      <div className={"desc"}>
                        {t('home_reject_improving_credit')}
                      </div>
                    </div>
                    <div className={"more"}>
                      {t("home_tenure")} <span
                        className={"num"}> {item.GFRghfN} </span> {t("home_days")}
                    </div>
                  </div>
                  <div className={"amount-row"}>
                    <div className={"text"}>
                      <div className={"tt"}>{t('home_max_loan_amount')}</div>
                      <div className={"amount"}>₹{number_format(item.golQjjt, 0)}</div>
                    </div>
                    <div className={"button disabled"}>{t('home_reject_button')}</div>
                  </div>
                </div>
              }
              {
                item.CvyoEsE === 3 &&
                <div className={"more-product"}>
                  <div className={"title-row"}>
                    <img className={"icon"} src={item.ukGVFkv} alt={""} />
                    <div className={"text"}>
                      <div className={"product-name"}>
                        {item.fttlALU}
                      </div>
                      <div className={"desc"}>
                        {t("home_disbursement")}
                      </div>
                    </div>
                    <div className={"more"}>
                      {t("home_tenure")} <span
                        className={"num"}> {item.GFRghfN} </span> {t("home_days")}
                    </div>
                  </div>
                  <div className={"amount-row"}>
                    <div className={"text"}>
                      <div className={"tt"}>{t('home_loan_amount')}</div>
                      <div className={"amount"}>₹{number_format(item.golQjjt, 0)}</div>
                    </div>
                    <div className={"button"} onClick={() => {
                      setSpeedUpTimes(speedUpTimes => speedUpTimes + 1)
                      Modal.alert({
                        content: speedUpTimes > 0 ? t('home_speed_up_disbursement_text_b') : t('home_speed_up_disbursement_text_a'),
                        confirmText: "Confirm",
                        cancelText: "Cancel",
                      })
                    }}>{t('home_speed_up')}</div>
                  </div>
                </div>
              }
              {
                item.CvyoEsE === 4 &&
                <div className={"more-product"}>
                  <div className={"title-row"}>
                    <img className={"icon"} src={item.ukGVFkv} alt={""} />
                    <div className={"text"}>
                      <div className={"product-name"}>
                        {item.fttlALU}
                      </div>
                      <div className={"desc"}>
                        {t('home_Loan disbursement failed. Update bank info now')}
                      </div>
                    </div>
                    <div className={"more"}>
                      {t("home_tenure")} <span
                        className={"num"}> {item.GFRghfN} </span> {t("home_days")}
                    </div>
                  </div>
                  <div className={"amount-row"}>
                    <div className={"text"}>
                      <div className={"tt"}>{t('home_loan_amount')}</div>
                      <div className={"amount"}>₹{number_format(item.golQjjt, 0)}</div>
                    </div>
                    <div className={"button"} onClick={() => {
                      navi("/modify-bank", {
                        state: {
                          productId: item.ZqRzKYj,
                          amount: item.MHQPOox,
                          tenure: item.GFRghfN
                        }
                      })
                    }}>{t('home_modify_now')}</div>
                  </div>
                </div>
              }
              {
                item.CvyoEsE === 6 &&
                <div className={"more-product"}>
                  <div className={"title-row"}>
                    <img className={"icon"} src={item.ukGVFkv} alt={""} />
                    <div className={"text"}>
                      <div className={"product-name"}>
                        {item.fttlALU}
                      </div>
                      <div className={"desc"}>
                        {t('home_withdraw_now')}
                      </div>
                    </div>
                    <div className={"more"}>
                      {t("home_tenure")} <span
                        className={"num"}> {item.GFRghfN} </span> {t("home_days")}
                    </div>
                  </div>
                  <div className={"amount-row"}>
                    <div className={"text"}>
                      <div className={"tt"}>{t('home_loan_amount')}</div>
                      <div className={"amount"}>₹{number_format(item.golQjjt, 0)}</div>
                    </div>
                    <div className={"button"} onClick={() => {
                      navi("/reloan", {
                        state: {
                          productId: item?.ZqRzKYj,
                          amount: item?.golQjjt,
                          tenure: item?.GFRghfN
                        }
                      })
                    }}>{t('home_withdraw_now')}</div>
                  </div>
                </div>
              }
              {
                item.CvyoEsE === 7 &&
                <div className={"more-product"}>
                  <div className={"title-row"}>
                    <img className={"icon"} src={item.ukGVFkv} alt={""} />
                    <div className={"text"}>
                      <div className={"product-name"}>
                        {item.fttlALU}
                      </div>
                      <div className={"desc"}>
                        {t('home_withdraw_now')}
                      </div>
                    </div>
                    <div className={"more"}>
                      {t("home_tenure")} <span
                        className={"num"}> {item.GFRghfN} </span> {t("home_days")}
                    </div>
                  </div>
                  <div className={"amount-row"}>
                    <div className={"text"}>
                      <div className={"tt"}>{t('home_loan_amount')}</div>
                      <div className={"amount"}>₹{number_format(item.golQjjt, 0)}</div>
                    </div>
                    <div className={"button"} onClick={() => {
                      navi("/confirm", {
                        state: {
                          productId: item?.ZqRzKYj,
                          amount: item?.golQjjt,
                          tenure: item?.GFRghfN
                        }
                      })
                    }}>{t('home_withdraw_now')}</div>
                  </div>
                </div>
              }
              {
                !item.CvyoEsE &&
                <div className={"more-product"}>
                  <div className={"title-row"}>
                    <img className={"icon"} src={item.ukGVFkv} alt={""} />
                    <div className={"text"}>
                      <div className={"product-name"}>
                        {item.fttlALU}
                      </div>
                      <div className={"desc"}>
                        {item.VfYzXTZ}
                      </div>
                    </div>
                    <div className={"more"}>
                      {t("home_tenure")} <span
                        className={"num"}> {item.GFRghfN} </span> {t("home_days")}
                    </div>
                  </div>
                  <div className={"amount-row"}>
                    <div className={"text"}>
                      <div className={"tt"}>{t('home_max_loan_amount')}</div>
                      <div className={"amount"}>₹{number_format(item.golQjjt, 0)}</div>
                    </div>
                    <div className={item.lLUzKnf === 0 ? "button disabled" : "button"} onClick={() => {
                      if (item.lLUzKnf === 0) {
                        return
                      }
                      switch (productInfo.QiEleUB) {
                        case 1:
                          navi("/info", {
                            state: {
                              productId: item?.ZqRzKYj,
                              amount: item?.golQjjt,
                              tenure: item?.GFRghfN
                            }
                          })
                          break
                        case 2:
                          navi("/contact", {
                            state: {
                              productId: item?.ZqRzKYj,
                              amount: item?.golQjjt,
                              tenure: item?.GFRghfN
                            }
                          })
                          break
                        case 3:
                          navi("/bank", {
                            state: {
                              productId: item?.ZqRzKYj,
                              amount: item?.golQjjt,
                              tenure: item?.GFRghfN
                            }
                          })
                          break
                        case 4:
                          navi("/face", {
                            state: {
                              productId: item?.ZqRzKYj,
                              amount: item?.golQjjt,
                              tenure: item?.GFRghfN
                            }
                          })
                          break
                        default:
                          navi("/kyc", {
                            state: {
                              productId: item?.ZqRzKYj,
                              amount: item?.golQjjt,
                              tenure: item?.GFRghfN
                            }
                          })
                          break
                      }
                    }}>{t('home_start_application')}</div>
                  </div>
                </div>
              }
              {
                item.CvyoEsE === 8 &&
                <div className={"more-product"}>
                  <div className={"title-row"}>
                    <img className={"icon"} src={item.ukGVFkv} alt={""} />
                    <div className={"text"}>
                      <div className={"product-name"}>
                        {item.fttlALU}
                      </div>
                      <div className={"desc"}>
                        {item.VfYzXTZ}
                      </div>
                    </div>
                    <div className={"more"}>
                      {t("home_tenure")} <span
                        className={"num"}> {item.GFRghfN} </span> {t("home_days")}
                    </div>
                  </div>
                  <div className={"amount-row"}>
                    <div className={"text"}>
                      <div className={"tt"}>{t('home_max_loan_amount')}</div>
                      <div className={"amount"}>₹{number_format(item.golQjjt, 0)}</div>
                    </div>
                    <div className={"button disabled"} onClick={() => {

                    }}>{t('Pending unlock')}</div>
                  </div>
                </div>
              }
              {
                item.CvyoEsE === 5 &&
                <div className={"more-product main-product"}
                  style={{ height: "unset", padding: ".16rem 0", width: "3.27rem" }}>
                  <div className={"product-name"}>
                    <div className={"product-image"}><img src={item.ukGVFkv} alt="" /></div>
                    <div className={"text"}>{item.fttlALU}</div>
                    {item.mbqNnwy === 1 && item.HaxfuiV === 0 &&
                      <div className={"due-days"}>{t('home_due_today')}</div>
                    }
                    {item.mbqNnwy === 1 && item.HaxfuiV === 1 &&
                      <div className={"due-days"}>{t('home_due_in')}<span
                        className={"num"}>{item.HaxfuiV}</span>{t('home_day')}</div>
                    }
                    {item.mbqNnwy === 1 && item.HaxfuiV > 1 &&
                      <div className={"due-days"}>{t('home_due_in')}<span
                        className={"num"}>{item.HaxfuiV}</span>{t('home_days')}</div>
                    }
                    {item.mbqNnwy === 0 && item.HaxfuiV === 1 &&
                      <div className={"due-days"}><span
                        className={"num"}>{item.HaxfuiV}</span>{t('home_day')} {t('home_overdue')}</div>
                    }
                    {item.mbqNnwy === 0 && item.HaxfuiV > 1 &&
                      <div className={"due-days"}><span
                        className={"num"}>{item.HaxfuiV}</span>{t('home_days')} {t('home_overdue')}</div>
                    }
                  </div>
                  <div className={"amount-title"}>{t("home_repayment_due")}</div>
                  <div className={"amount"}>₹{number_format(item.xNOMEAs, 0)}</div>
                  <div className={"due-date"}>{t("home_due_date")} {item.bbHjcmM} </div>
                  <div className={"button-repay"} onClick={() => {
                    getRepayLink({
                      QEJLvVNn: item.NhZcaWw,
                      aUEolrKf: 1,
                      sdjtTfUP: item.xNOMEAs
                    }).then((res) => {
                      let ver = getCookie('client_version')
                      if (res.code === 200) {
                        if (getCookie("model") !== "android" && compareVersion(ver, "1.0.1") === 1) {
                          window.location.href = "jzrhscheme://openWebVC?action=openWebVC&url=" + encodeURIComponent(res.data)
                        } else {
                          window.location.href = res.data
                        }
                      } else {
                        Toast.show({ content: res.message })
                      }
                    })
                  }}>{t('home_full_repayment')} ₹{number_format(item.xNOMEAs, 0)}</div>
                  <div className={"button-extension"} onClick={() => {
                    getRepayLink({
                      QEJLvVNn: item.NhZcaWw,
                      aUEolrKf: 2,
                      sdjtTfUP: item?.hqErHdj
                    }).then((res) => {
                      let ver = getCookie('client_version')
                      if (res.code === 200) {
                        if (getCookie("model") !== "android" && compareVersion(ver, "1.0.1") === 1) {
                          window.location.href = "jzrhscheme://openWebVC?action=openWebVC&url=" + encodeURIComponent(res.data)
                        } else {
                          window.location.href = res.data
                        }
                      } else {
                        Toast.show({ content: res.message })
                      }
                    })
                  }}><img src={extensionTip} alt="" className={"tip"} onClick={(e) => {
                    e.stopPropagation()
                    Toast.show({ content: t('home_extension_tip') })
                  }} /> {t('home_extension')} ₹{number_format(item?.hqErHdj, 0)}</div>
                </div>
              }
            </div>
          })
        }
      </div>
    </PullToRefresh>
    {
      init && isNewUser && newUserText &&
      <div className={"welcome"}>
        <div className={"text-content"}>
          <div className={"title"}>{t("welcome_title")}</div>
          <div className={"content"}>{newUserText}</div>
        </div>
        <div className={"apply"}>
          <img src={welcomeApply} alt="" />
        </div>
        <div className={"confirm-button"} onClick={() => {
          setIsNewUser(false)
          localStorage.removeItem("isNewUser")
          document.cookie = "isNewUser" + phone + "=1; path=/"
        }}>{t("welcome_confirm")}</div>
        <div className={"arrow"}>
          <img src={welcomeArrow} alt="" />
        </div>
      </div>
    }
    <Modal title={null} header={null} footer={null} wrapClassName={"login-tip"} centered={true} destroyOnClose={true}
      onOk={() => {
        setIsShowTip(false)
      }} open={isShowTip} closeIcon={null}>
      <div>{tip}</div>
      <div className={"button"} onClick={() => {
        setIsShowTip(false)
      }}>{t("home_tip_confirm")}</div>
    </Modal>
    <Footer current={"home"}></Footer>
  </>)
}
