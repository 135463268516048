// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-container {
  padding-top: 0.88rem;
}
.privacy-container .title {
  width: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.44rem;
  height: 0.44rem;
  font-size: 0.16rem;
  color: #333333;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 65535;
  background: #FFFFFF;
}
.privacy-container .title .back {
  position: absolute;
  width: 0.64rem;
  height: 0.44rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  top: 0.44rem;
  left: 0;
}
.privacy-container .title .back img {
  width: 0.18rem;
  height: 0.18rem;
}
.privacy-container .content {
  padding: 0.24rem;
  font-size: 0.16rem;
  color: #333333;
  line-height: 0.28rem;
}
.privacy-container .button {
  margin: 0 0.24rem 0.24rem 0.24rem;
  width: 3.27rem;
  height: 0.64rem;
  background: #1DCFB7;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: white;
  font-size: 0.16rem;
}
`, "",{"version":3,"sources":["webpack://./src/assets/less/privacy.less"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AAFA;EAGI,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,OAAA;EACA,MAAA;EACA,cAAA;EACA,mBAAA;AAEJ;AAjBA;EAkBM,kBAAA;EACA,cAAA;EACA,eAAA;EACA,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,uBAAA;EAKA,YAAA;EACA,OAAA;AAFN;AA5BA;EA0BQ,cAAA;EACA,eAAA;AAKR;AAhCA;EAkCI,gBAAA;EACA,kBAAA;EACA,cAAA;EACA,oBAAA;AACJ;AAtCA;EAwCI,iCAAA;EACA,cAAA;EACA,eAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;EACA,YAAA;EACA,kBAAA;AACJ","sourcesContent":[".privacy-container {\n  padding-top: .88rem;\n  .title {\n    width: 3.75rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding-top: .44rem;\n    height: .44rem;\n    font-size: 0.16rem;\n    color: #333333;\n    position: fixed;\n    left: 0;\n    top: 0;\n    z-index: 65535;\n    background: #FFFFFF;\n\n    .back {\n      position: absolute;\n      width: .64rem;\n      height: .44rem;\n      display: flex;\n      align-content: center;\n      align-items: center;\n      justify-content: center;\n      img {\n        width: .18rem;\n        height: .18rem;\n      }\n      top: 0.44rem;\n      left: 0;\n    }\n  }\n  .content {\n    padding: .24rem;\n    font-size: .16rem;\n    color: #333333;\n    line-height: .28rem;\n  }\n  .button {\n    margin: 0 .24rem .24rem .24rem;\n    width: 3.27rem;\n    height: .64rem;\n    background: #1DCFB7;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    align-content: center;\n    color: white;\n    font-size: .16rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
