export const generateID = () => {
  let t = Date.now()
  let r = Math.floor(Math.random() * (999 - 100 + 1) + 100)
  return `${t}${r}`.substring(1)
}

export function number_format (number, decimals, dec_point, thousands_sep, roundtag) {
  /*
  * 参数说明：
  * number：要格式化的数字
  * decimals：保留几位小数
  * dec_point：小数点符号
  * thousands_sep：千分位符号
  * roundtag:舍入参数，默认 "ceil" 向上取,"floor"向下取,"round" 四舍五入
  * */
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  roundtag = roundtag || "ceil" //"ceil","floor","round"
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = '',
    toFixedFix = function (n, prec) {

      var k = Math.pow(10, prec)
      console.log()

      return '' + parseFloat(Math[roundtag](parseFloat((n * k).toFixed(prec * 2))).toFixed(prec * 2)) / k
    }
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  var re = /(-?\d+)(\d{3})/
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, "$1" + sep + "$2")
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}

export function getCookie (cookieName) {
  if (cookieName == 'token') {
    console.log('====================================')
    console.log('getCookie拿到的token', localStorage.getItem('token'))
    console.log('====================================')
    return localStorage.getItem('token')
  }
  const strCookie = document.cookie
  const cookieList = strCookie.split(';')

  for (let i = 0; i < cookieList.length; i++) {
    const arr = cookieList[i].split('=')
    if (cookieName === arr[0].trim()) {
      return arr[1]
    }
  }

  return ''
}

/**
 * 比较两个版本号的大小
 * @param {string} v1
 * @param {string} v2
 * @returns {0|1|-1} 0表示v1 = v2，1表示v1 > v2，-1表示v1 < v2
 *
 * compareVersion('1.1.0', '1.1.0');      // => 0
 * compareVersion('1.20.0', '1.2.20');    // => 1
 * compareVersion('v2.0.30', 'v1.9.10');  // => 1
 * compareVersion('v1.1.40', 'v1.2.0');   // => -1
 */
export function compareVersion (v1, v2) {
  let cpResult
  let i = 0
  const arr1 = v1.replace(/[^0-9.]/, '').split('.')
  const arr2 = v2.replace(/[^0-9.]/, '').split('.')
  while (true) {
    const s1 = arr1[i]
    const s2 = arr2[i++]
    if (s1 === undefined || s2 === undefined) {
      cpResult = arr1.length - arr2.length
      break
    }
    if (s1 === s2) continue
    cpResult = s1 - s2
    break
  }
  // eslint-disable-next-line
  return cpResult > 0 ? 1 : cpResult === 0 ? 0 : -1
}

export function trim (str, char, type) {
  if (char) {
    if (type === 'left') {
      return str.replace(new RegExp('^\\' + char + '+', 'g'), '')
    } else if (type === 'right') {
      return str.replace(new RegExp('\\' + char + '+$', 'g'), '')
    }
    return str.replace(new RegExp('^\\' + char + '+|\\' + char + '+$', 'g'), '')
  }
  return str.replace(/^\s+|\s+$/g, '')
}

export function insertRandomNumbers (inputString) {
  const numberOfDigits = 5

  // 生成 5 个随机数字
  const randomNumbers = []
  for (let i = 0; i < numberOfDigits; i++) {
    randomNumbers.push(Math.floor(Math.random() * 10))
  }

  // 将数字插入字符串
  let resultString = ''
  for (let i = 0; i < inputString.length; i++) {
    resultString += inputString[i]
    if (i < inputString.length - 1 && Math.random() < 0.5 && randomNumbers.length > 0) {
      resultString += randomNumbers.pop()
    }
  }

  return resultString
}
export function uploadImage (val) {
  // window.webkit.messageHandlers.loadingStart.postMessage('loadingStart')
  return new Promise((resolve, reject) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'image/*'
    input.capture = 'camera'
    input.click()
    input.onchange = (e) => {
      const file = e.target.files[0]
      if (!file) {
        reject()
        return
      }
      const xhr = new XMLHttpRequest()
      xhr.open('POST', `${process.env.REACT_APP_API_URL2}` + insertRandomNumbers("tQqKfMGFRL"))
      xhr.setRequestHeader("Authorization", getCookie("token"))
      xhr.setRequestHeader("x-platform", "#FFFFFF")
      let packageName = getCookie("packageName")
      if (getCookie("model") !== "android" && !packageName) {
        packageName = "com.Mohd.FocusDigits"
      }
      let appId = getCookie('AppId')
      if (!appId) {
        if (trim(getCookie("packageName")) === 'com.xuycctu.digitalpath') {
          appId = 9
        } else {
          appId = 8
        }
      }
      xhr.setRequestHeader("appId", appId)
      xhr.setRequestHeader("packageName", packageName)
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.responseText))
          } else {
            reject(JSON.parse(xhr.responseText))
          }
        }
      }
      const formData = new FormData()
      formData.append('sZacgJWY', file)
      formData.append('FZiGSywo', val)
      xhr.send(formData)
    }
  })
}
