import CryptoJS from 'crypto-js';

// 加密
export function encryptAES(data, key, iv) {
  console.log('data', data, 'key', key, 'iv', iv);
  const cipherText = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return cipherText.toString();
}

// 解密
export function decryptAES(cipherText, key, iv) {
  const decrypted = CryptoJS.AES.decrypt(cipherText, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}
//
// // 例子
const plaintext = '{"applyProduct":"6"}';
const key = 'il2rgixyzy8cxDyu'; // 16 bytes key
const iv = 'aXg7mnobIandVzze'; // 16 bytes IV

const encryptedText = encryptAES(plaintext, key, iv);
console.log('Encrypted:', encryptedText);
//
// const decryptedText = decryptAES(encryptedText, key, iv);
// console.log('Decrypted:', decryptedText);
