import request from "./request";

export function getProductInfo(data) {
  return request({
    url: "/CWJZBYfwW6rd",
    headers: {
      isToken: true,
    },
    method: "get",
    params: data,
  });
}

export function getDict(data) {
  return request({
    url: "/mhHYs0kIeG8W",
    headers: {
      isToken: true,
    },
    method: "get",
    params: data,
  });
}
export function checkToken(data) {
  return request({
    url: "/7z7AXiQe1zfR",
    headers: {
      isToken: true,
    },
    method: "get",
    params: data,
  });
}
export function getReloanPriceList(data) {
  return request({
    url: "/Z9zgHlp3O2CU",
    headers: {
      isToken: true,
    },
    method: "get",
    params: data,
  });
}

export function saveIDCardInfo(data) {
  return request({
    url: "/gNdAFbZsNxWw",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  });
}


export function savePersonalInfo(data) {
  return request({
    url: "/umklAVRK1gm6",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  });
}

export function saveContact(data) {
  return request({
    url: "/mmWhZEry9J9l",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  });
}

export function saveBank(data) {
  return request({
    url: "/VumIuAJs1OdP",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  });
}

export function faceVerify(data, packageName) {
  return request({
    url: "/f1pAstdj0aEp",
    headers: {
      isToken: true,
      packageName: packageName,
    },
    method: "post",
    data: data,
  });
}

export function getConfirmInfo(data) {
  return request({
    url: "/NFgv8Hhj5qlg",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  });
}
export function getConfirmInfoNew(data) {
  return request({
    url: "/KeHgbyK7xVGL",
    headers: {
      isToken: true,
    },
    method: "get",
    params: data,
  });
}

export function confirmInfo(data) {
  return request({
    url: "/3TP0aQeq9CjQ",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  });
}
export function getBankInfo(data) {
  return request({
    url: "/m750tr5rnz4R",
    headers: {
      isToken: true,
    },
    method: "get",
    params: data,
  });
}
export function getOrders(data) {
  return request({
    url: "/yIA6STga1nDX",
    headers: {
      isToken: true,
    },
    method: "get",
    params: data,
  });
}
export function modifyBank(data) {
  return request({
    url: "/Q2EqbCVAdTD4",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  });
}

export function getRepayLink(data) {
  return request({
    url: "/o6eKUeYFNefu",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  });
}
export function addFeedback(data) {
  return request({
    url: "/kW9lbB91M14Q",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  });
}

export function riskUpload(data) {
  return request({
    url: "/x47PSTMmoEIg",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  });
}


export function getOnlineCustomService(data) {
  return request({
    url: "/9yTXdXdNUb3B",
    headers: {
      isToken: true,
    },
    method: "get",
    params: data,
  });
}



export function uploadStat(data) {
  return request({
    url: "/bOAY5G5DABNc",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
  });
}

