import axios from 'axios'
import { tansParams, blobValidate } from '../../utils/ruoyi'
import cache from '../../utils/cache'
import { saveAs } from 'file-saver'
import { Modal, Toast, DotLoading } from 'antd-mobile'
import ReactDOM from "react-dom/client"
import { getCookie, trim } from "../../utils/tools"
import { encryptAES } from "../../utils/aes"

//获取token
let getAppId = () => {
  return new Promise((resolve, reject) => {
    let appId
    // if (appId) {
    //   resolve(appId)
    // } else {
    let getLoginInfo = (message) => {
      appId = JSON.parse(message).AppId
      localStorage.setItem('appid', appId)
      resolve(appId)
    }
    window.getLoginInfo = getLoginInfo
    window.webkit.messageHandlers.getLoginInfo.postMessage('getLoginInfo')
    // }
  })
}

let CancelToken = axios.CancelToken
let source = CancelToken.source()
let httpRequestCount = 0

const showLoading = () => {
  if (httpRequestCount === 0) {
    const loadingContainer = document.createElement('div')
    loadingContainer.setAttribute('id', 'axiosLoading')
    document.body.appendChild(loadingContainer)
    const render = ReactDOM.createRoot(loadingContainer)
    render.render(<span
      style={{ fontSize: ".32rem", background: "#ffffff", borderRadius: ".06rem", padding: ".32rem .12rem" }}><DotLoading
        toast color={"#1DCFB7"} animating /></span>)
  }
  httpRequestCount++
}
const hideLoading = () => {
  httpRequestCount--
  if (httpRequestCount <= 0) {
    httpRequestCount = 0
    const loadingContainer = document.querySelector('#axiosLoading')
    if (loadingContainer) {
      document.querySelector('body').removeChild(loadingContainer)
    }
  }
}

axios.source = source
// 是否显示重新登录
export let isRelogin = { show: false }

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: `${process.env.REACT_APP_API_URL2}`,
  // 超时
  timeout: 30000
})
const getToken = () => {
  // return getCookie("token")
  // getAppId()
  // console.log('====================================')
  // console.log('v2 request 拿的token：', localStorage.getItem('token'),getCookie("token"))
  // console.log('====================================')
  return localStorage.getItem('token')
}
// request拦截器
service.interceptors.request.use(async config => {
  let loading = typeof config.data === "object" ? config.data?.noLoading : (typeof config.params === "object" ? config.params?.noLoading : false)
  console.log("loading:", loading)
  if (loading == false || loading == undefined) {
    showLoading()
  }
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  config.headers['x-platform'] = '#FFFFFF'
  let packageName = getCookie("packageName")
  if (getCookie("model") !== "android" && !packageName) {
    packageName = "com.Mohd.FocusDigits"
  }
  let appId = localStorage.getItem('appid')


  // console.log('appId', appId)
  // console.log("请求获取cookie：潘", document.cookie)


  if (!appId) {
    appId = await getAppId()
    // if (trim(getCookie("packageName")) === 'com.xuycctu.digitalpath') {
    //   appId = 9
    // } else {
    //   appId = 8
    // }
  } else {
    appId = localStorage.getItem('appid')
  }


  config.headers['appId'] = 12
  config.headers['packageName'] = packageName
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params)
    url = url.slice(0, -1)
    config.params = {}
    config.url = url
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url                // 请求地址
      const s_data = sessionObj.data              // 请求数据
      const s_time = sessionObj.time              // 请求时间
      const interval = 2000                       // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = 'loading...'
        console.warn(`[${s_url}]: ` + message)
        return Promise.reject(new Error(message))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  if (!config.headers?.noAes) {
    config.headers['Content-Type'] = 'text/plain'
    let aesKey = sessionStorage.getItem("aesKey")
    const aes = JSON.parse(aesKey)
    config.data = encryptAES(JSON.stringify(config.data), aes.key, aes.iv)
    console.log(config.data)
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  let loading = typeof res.config.data === "object" ? res.config.data?.noLoading : (typeof res.config.params === "object" ? res.config.params?.noLoading : false)
  console.log("loading:", loading)
  if (loading == false || loading == undefined) {
    hideLoading()
  }
  // 未设置状态码则默认成功状态
  const code = res.data && ("code" in res.data) ? res.data.code : res.status
  // 获取错误信息
  const msg = res.data.message || "system request fail."
  // 二进制数据则直接返回
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data
  }
  if (code === 8004 || code === 8005) {
    if (!isRelogin.show) {
      isRelogin.show = true
      Modal.confirm({
        content: 'login expired, stay here or go to login', title: 'system tip',
        confirmText: "Confirm",
        cancelText: "Cancel",
        onConfirm: () => {
          isRelogin.show = false
          window.location.href = "jzrhscheme://loginVC?action=loginVC"
          setTimeout(() => {
            console.log(res)
            window.location.href = "/#/v2/login"
          }, 1000)
        }
      })
    }
    return Promise.reject('login expired。')
  } else if (code === 500) {
    Toast.show({ content: msg })
    return Promise.reject(new Error(msg))
  } else if (code === 601) {
    Toast.show({ content: msg })
    return Promise.reject(new Error(msg))
  } else if (code !== 200) {
    Toast.show({ content: msg })
    return Promise.reject(res.data)
  } else {
    return Promise.resolve(res.data)
  }
},
  error => {
    hideLoading()
    console.log('err' + error)
    let msg = error.message
    if (msg === "Network Error") {
      msg = "request backend error"
    } else if (msg.includes("timeout")) {
      msg = "request timeout"
    } else if (msg.includes("Request failed with status code")) {
      msg = "api:" + msg.substr(msg.length - 3) + " error"
    }
    Toast.show({ content: msg })
    return Promise.reject(error)
  }
)

// 通用下载方法
export function download (url, params, filename, config) {
  return service.post(url, params, {
    transformRequest: [(params) => {
      return tansParams(params)
    }],
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    ...config
  }).then(async (data) => {
    const isBlob = blobValidate(data)
    if (isBlob) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text()
      const rspObj = JSON.parse(resText)
      const errMsg = rspObj.msg
      Toast.show({ content: errMsg })
    }
  }).catch((r) => {
    console.error(r)
    Toast.show({ content: 'download file error!' })
  })
}

export default service
