import "../../../assets/less/home.less"
import logo from "../../../assets/images/home_logo.webp"
import help from "../../../assets/images/icon-help.webp"
import { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"
import { PullToRefresh, Modal, Button } from "antd-mobile"
import { compareVersion, getCookie, number_format } from "../../../utils/tools"
import { useNavigate } from "react-router-dom"
import { logEvent } from "firebase/analytics"
import { reactLocalStorage } from "reactjs-localstorage"
import {
  getProductList,
  getCustomerInfo,
  uploadStat,
  getReportPoint,
  isModel6666,
} from "../../../api/v2/loan"
import FooterV2 from "../../../components/footer/indexv2"

export default function Home (props) {
  const [t] = useTranslation()
  const navi = useNavigate()
  let [token, setToken] = useState(null)
  const [productInfo, setProductInfo] = useState({})
  const [pageViewStart, setPageViewStart] = useState(0)

  let getToken = () => {
    return new Promise((resolve, reject) => {
      let getLoginInfo = (message) => {
        console.log('====================================')
        console.log('page get new login info aaaaaa:', message)
        console.log('====================================')
        let token = JSON.parse(message).token
        let appid = JSON.parse(message).AppId
        let phoneNum = JSON.parse(message).phoneNum
        // console.log('====================================')
        // console.log(JSON.parse(message))
        // console.log('====================================')
        setToken(token) // 更新 token 状态

        if (token) {
          localStorage.setItem('token', token)
        } else {
          token = null
        }
        if (appid) {
          localStorage.setItem('appid', appid)
        } else {
          appid = null
        }
        if (phoneNum) {
          localStorage.setItem('phoneNum', phoneNum)
        } else {
          phoneNum = null
        }

        resolve(token)
      }

      window.getLoginInfo = getLoginInfo
      window.webkit.messageHandlers.getLoginInfo.postMessage('getLoginInfo')
    })
  }
  useEffect(() => {

    let fetchData = async () => {
      try {
        let r = await getToken()
        setToken(r)
        // 在这里可以继续处理获取到的 token
        if (token === null) {
          // alert(document.cookie)
          console.log(document.cookie)
          // if (navigator.userAgent.indexOf("flashbee") !== -1) {
          token = getCookie("token")
          if (token) {
            setToken(token)
            localStorage.setItem("x-token", token)
          } else {
            token = localStorage.getItem("x-token")
            if (token) {
              setToken(token)
            }
          }
          if (token) {
            setUserProperties(analytics, {
              flashb_side: getCookie("packageName"),
              flashb_country: "in",
              flashb_logined: 1,
              flashb_black: 0,
              flashb_refuse: 0,
            })
          }
          setInit(true)
        }
      } catch (error) {
        console.error('获取 token 失败:', error)
      }
    }
    fetchData()

  }, [token])
  useEffect(() => {
    document.title = "home"
    reactLocalStorage.set("currentPage", "home")
    setPageViewStart(Date.now())

    logEvent(props.analytics, "page_view", {
      page_title: reactLocalStorage.get("currentPage"),
      page_path: "/home",
      page_location: window.location.href,
      page_referrer: "",
    })
  }, [])
  const [init, setInit] = useState(false)
  const [customLink, setCustomLink] = useState("")
  useEffect(() => {
    getProductList({})
      .then((res) => {
        let d = res.data?.list || []
        console.log('====================================')
        console.log(d)
        console.log('====================================')
        setProductInfo(d)
        setInit(true)
      })
      .catch(() => { })
    if (getCookie("model") === "android") {
      getCustomerInfo({
        MsEAjMV: 2,
      })
        .then((res) => {
          setCustomLink(res?.data?.customer_serviceLink)
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      getCustomerInfo({
        MsEAjMV: 3,
      })
        .then((res) => {
          setCustomLink(res?.data?.customer_serviceLink)
        })
        .catch((e) => {
          console.log(e)
        })
    }
    getReportPoint()
      .then((res) => {

        if (res && res.code === 200) {
          if (res.data.firstLoanSuccess === "0") {
            window.webkit.messageHandlers.FBLogFirstloansuccess.postMessage('FBLogFirstloansuccess')
          }
          if (res.data.firstReLoanSuccess === "0") {
            window.webkit.messageHandlers.FBLogRepayment.postMessage('FBLogRepayment')
          }
          if (res.data.firstApproval === "0") {
            window.webkit.messageHandlers.FBLogApplicationsuccess.postMessage('FBLogApplicationsuccess')

          }
          if (res.data.firstSubmitOrder === "0") {
            window.webkit.messageHandlers.FBLogApple.postMessage('FBLogApple')

          }
        }
      })
      .catch()
    const interval = setInterval(() => {
      getProductList({
        noLoading: true,
      })
        .then((res) => {
          let d = res.data?.list || []
          res.data?.list.find(item => {
            if (item.status == 6) {
              window.location.href =
                "jzrhscheme://openCamera?action=FBLogFirstloansuccess&callback="
            }
          })

          setProductInfo(d)
          setInit(true)
        })
        .catch(() => { })
    }, 90000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <>
      <PullToRefresh
        onRefresh={() => {
          getReportPoint()
            .then((res) => {

              if (res && res.code === 200) {
                if (res.data.firstLoanSuccess === "0") {
                  window.webkit.messageHandlers.FBLogFirstloansuccess.postMessage('FBLogFirstloansuccess')
                }
                if (res.data.firstReLoanSuccess === "0") {
                  window.webkit.messageHandlers.FBLogRepayment.postMessage('FBLogRepayment')
                }
                if (res.data.firstApproval === "0") {
                  window.webkit.messageHandlers.FBLogApplicationsuccess.postMessage('FBLogApplicationsuccess')

                }
                if (res.data.firstSubmitOrder === "0") {
                  window.webkit.messageHandlers.FBLogApple.postMessage('FBLogApple')

                }
              }
            })
            .catch()
          getProductList({})
            .then((res) => {
              let d = res.data?.list || []
              setProductInfo(d)
              console.log(res)
              // if (d) {
              //   d.find(item => {
              //     if (item.status == 6) {
              //       window.location.href =
              //         "jzrhscheme://openCamera?action=FBLogFirstloansuccess&callback="
              //     }
              //   })
              // }
            })
            .catch(() => { })
        }}
      >
        <div className={"home"}>
          <div className={"nav"}></div>
          <div className={"top"}>
            <img className={"logo"} src={logo} alt="" />
            <img
              className={"help"}
              src={help}
              alt=""
              onClick={() => {
                let ver = getCookie("client_version")
                if (getCookie("model") !== "android") {
                  window.location.href =
                    "jzrhscheme://openWebVC?action=openWebVC&url=" +
                    encodeURIComponent(customLink)
                } else {
                  window.location.href = customLink
                }
              }}
            />
          </div>
          {init &&
            productInfo.map((item, key) => {
              return (
                <div key={key}>
                  {item.status === 0 && (
                    <div className={"main-product"}>
                      <div className={"product-name"}>
                        <div className={"product-image"}>
                          <img src={item.imageUrl} alt="" />
                        </div>
                        <div className={"text"}>{item.productName}</div>
                      </div>
                      <div className={"amount-title"}>
                        {t("home_loan_amount")}
                      </div>
                      <div className={"amount"}>
                        ₹{number_format(item.maxAmount, 0)}
                      </div>
                      <div className={"period"}>
                        {t("home_tenure")}{" "}
                        <span className={"num"}> {item.days} </span>{" "}
                        {t("home_days")}
                      </div>
                      <div className={"bank"}>
                        {t("home_daily_interest")}{" "}
                        <span className={"num"}> {item.interestRate}%</span>
                      </div>
                      <div
                        className={"button review"}
                        onClick={() => {
                          navi("/v2/review", {
                            state: {
                              productId: item.id,
                              loanOrderId: item.loanOrderId,
                              amount: item.maxAmount,
                              tenure: item.days,
                            },
                          })
                        }}
                      >
                        {t("Under Review")}
                      </div>
                    </div>
                  )}
                  {item.status === 6 && (
                    <div className={"main-product"}>
                      <div className={"product-name"}>
                        <div className={"product-image"}>
                          <img src={item.imageUrl} alt="" />
                        </div>
                        <div className={"text"}>{item.productName}</div>
                      </div>
                      <div className={"amount-title"}>
                        {t("home_loan_amount")}
                      </div>
                      <div className={"amount"}>
                        ₹{number_format(item.maxAmount, 0)}
                      </div>
                      <div className={"period"}>
                        {t("home_tenure")}{" "}
                        <span className={"num"}> {item.days} </span>{" "}
                        {t("home_days")}
                      </div>
                      <div className={"bank"}>
                        {t("home_daily_interest")}{" "}
                        <span className={"num"}> {item.interestRate}%</span>
                      </div>
                      <div
                        className={"button reject"}
                        onClick={() => {
                          navi("/v2/reject", {
                            state: {
                              productId: item.id,
                              loanOrderId: item.loanOrderId,
                              amount: item.maxAmount,
                              tenure: item.days,
                            },
                          })
                        }}
                      >
                        {t("Reject")}
                      </div>
                    </div>
                  )}
                  {item.status === 7 && (
                    <div className={"main-product"}>
                      <div className={"product-name"}>
                        <div className={"product-image"}>
                          <img src={item.imageUrl} alt="" />
                        </div>
                        <div className={"text"}>{item.productName}</div>
                      </div>
                      <div className={"amount-title"}>
                        {t("home_loan_amount")}
                      </div>
                      <div className={"amount"}>
                        ₹{number_format(item.maxAmount, 0)}
                      </div>
                      <div className={"period"}>
                        {t("home_tenure")}{" "}
                        <span className={"num"}> {item.days} </span>{" "}
                        {t("home_days")}
                      </div>
                      <div className={"bank"}>
                        {t("home_daily_interest")}{" "}
                        <span className={"num"}> {item.interestRate}%</span>
                      </div>
                      <div
                        className={"button review"}
                        onClick={() => {
                          navi("/v2/modify-bank", {
                            state: {
                              productId: item.id,
                              loanOrderId: item.loanOrderId,
                              amount: item.maxAmount,
                              tenure: item.days,
                            },
                          })
                        }}
                      >
                        {t("home_modify_now")}
                      </div>
                    </div>
                  )}
                  {item.status === 3 && (
                    <div className={"main-product"}>
                      <div className={"product-name"}>
                        <div className={"product-image"}>
                          <img src={item.imageUrl} alt="" />
                        </div>
                        <div className={"text"}>{item.productName}</div>
                      </div>
                      <div className={"amount-title"}>
                        {t("home_max_loan_amount")}
                      </div>
                      <div className={"amount"}>
                        ₹{number_format(item.maxAmount, 0)}
                      </div>
                      <div className={"period"}>
                        {t("home_tenure")}{" "}
                        <span className={"num"}> {item.days} </span>{" "}
                        {t("home_days")}
                      </div>
                      <div className={"bank"}>
                        {t("home_daily_interest")}{" "}
                        <span className={"num"}> {item.interestRate}%</span>
                      </div>
                      <div
                        className={"button"}
                        onClick={() => {
                          isModel6666().then(async (res) => {
                            let appId = getCookie("packageName")
                            let newdata = res.data
                            console.log("packageName", appId)
                            console.log("newdata", newdata)

                            if (newdata.isOnline === 0 && appId == "com.Mohd.FocusDigits") {
                              const result = await Modal.confirm({
                                content: newdata.textShow,
                                confirmText: "confirm",
                                cancelText: "cancel",
                              })
                              if (result) {
                                window.location.href = "itms-apps://itunes.apple.com/app/id6475383568"
                              } else {
                                uploadStat({
                                  type: "page7",
                                  startTime: pageViewStart,
                                  endTime: Date.now(),
                                })
                                  .then()
                                  .catch()
                                navi("/v2/steps", {
                                  state: {
                                    productId: item.id,
                                    loanOrderId: item.loanOrderId,
                                    amount: item.maxAmount,
                                    tenure: item.days,
                                  },
                                })
                              }
                            } else {
                              uploadStat({
                                type: "page7",
                                startTime: pageViewStart,
                                endTime: Date.now(),
                              })
                                .then()
                                .catch()
                              navi("/v2/steps", {
                                state: {
                                  productId: item.id,
                                  loanOrderId: item.loanOrderId,
                                  amount: item.maxAmount,
                                  tenure: item.days,
                                },
                              })
                            }
                          })


                        }}
                      >
                        {t("Withdraw Now")}
                      </div>
                    </div>
                  )}
                  {!item.status && item.status !== 0 && (
                    <div className={"main-product"}>
                      <div className={"product-name"}>
                        <div className={"product-image"}>
                          <img src={item.imageUrl} alt="" />
                        </div>
                        <div className={"text"}>{item.productName}</div>
                      </div>
                      <div className={"amount-title"}>
                        {t("home_max_loan_amount")}
                      </div>
                      <div className={"amount"}>
                        ₹{number_format(item.maxAmount, 0)}
                      </div>
                      <div className={"period"}>
                        {t("home_tenure")}{" "}
                        <span className={"num"}> {item.days} </span>{" "}
                        {t("home_days")}
                      </div>
                      <div className={"bank"}>
                        {t("home_daily_interest")}{" "}
                        <span className={"num"}> {item.interestRate}%</span>
                      </div>
                      <div
                        className={"button"}
                        onClick={() => {
                          isModel6666().then(async (res) => {
                            // const newdata = res.data;
                            // const result = await Modal.confirm({
                            //   content: newdata.textShow,
                            //   confirmText:"confirm",
                            //   cancelText:"cancel"
                            // })
                            // if (result) {

                            // }

                            // return false

                            let appId = getCookie("packageName")
                            let newdata = res.data
                            console.log("packageName", appId)
                            console.log("newdata", newdata)
                            if (
                              newdata.isOnline === 0 &&
                              appId == "com.Mohd.FocusDigits"
                            ) {
                              const result = await Modal.confirm({
                                content: newdata.textShow,
                                confirmText: "confirm",
                                cancelText: "cancel",
                              })
                              if (result) {
                                window.location.href =
                                  "itms-apps://itunes.apple.com/app/id6475383568"
                              } else {
                                uploadStat({
                                  type: "page7",
                                  startTime: pageViewStart,
                                  endTime: Date.now(),
                                })
                                  .then()
                                  .catch()
                                navi("/v2/steps", {
                                  state: {
                                    productId: item.id,
                                    loanOrderId: item.loanOrderId,
                                    amount: item.maxAmount,
                                    tenure: item.days,
                                  },
                                })
                              }
                            } else {
                              uploadStat({
                                type: "page7",
                                startTime: pageViewStart,
                                endTime: Date.now(),
                              })
                                .then()
                                .catch()
                              navi("/v2/steps", {
                                state: {
                                  productId: item.id,
                                  loanOrderId: item.loanOrderId,
                                  amount: item.maxAmount,
                                  tenure: item.days,
                                },
                              })
                            }
                          })
                        }}
                      >
                        {t("Start Application")}
                      </div>
                    </div>
                  )}
                  {item.status === 2 && (
                    <div className={"main-product"}>
                      <div className={"product-name"}>
                        <div className={"product-image"}>
                          <img src={item.imageUrl} alt="" />
                        </div>
                        <div className={"text"}>{item.productName}</div>
                      </div>
                      <div className={"amount-title"}>
                        {t("home_loan_amount")}
                      </div>
                      <div className={"amount"}>
                        ₹{number_format(item.maxAmount, 0)}
                      </div>
                      <div className={"period"}>
                        {t("home_tenure")}{" "}
                        <span className={"num"}> {item.days} </span>{" "}
                        {t("home_days")}
                      </div>
                      <div className={"bank"}>
                        {t("home_daily_interest")}{" "}
                        <span className={"num"}> {item.interestRate}%</span>
                      </div>
                      <div
                        className={
                          item.statusLabel === "15"
                            ? "button overdue"
                            : "button"
                        }
                        onClick={() => {
                          navi("/v2/repay", {
                            state: {
                              productId: item.id,
                              loanOrderId: item.loanOrderId,
                              amount: item.maxAmount,
                              tenure: item.days,
                            },
                          })
                        }}
                      >
                        {t("Repay")}
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
        </div>
      </PullToRefresh>
      <FooterV2 current={"home"}></FooterV2>
    </>
  )
}
